import React, { Component } from 'react'

export default class PageNotFound extends Component {
    render() {
        return (
            <div className="containerStyle">
                <h5>Sorry..</h5>
               <h5> You dont have access to this page Please contact Administrator</h5> 
            </div>
        )
    }
}
