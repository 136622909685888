import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class AddRole extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)
        this. state = {
            validated: false,
            accessToken: ''
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
        });
        this.setState({ authorizedToReturn: true });
    }

    handleRoleIdChange = event => {
        this.setState({ roleId: event.target.value });
    }

    handleDescriptionChange = event => {
        this.setState({ description: event.target.value });
    }

    handleAuthorizedToReturnChange = event => {
        this.setState({ authorizedToReturn: event.target.checked });
    }

    handleAuthorizedToOverrideChange = event => {
        this.setState({ authorizedToOverride: event.target.checked });
    }

    backBtnClick = (event) => {
        event.preventDefault();
        window.location.href = '/roles';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const roleDetail = {
                roleId: this.state.roleId,
                description: this.state.description,
                authorizedToReturn: this.state.authorizedToReturn,
                authorizedToOverride: this.state.authorizedToOverride
            };
            getHttpCommon(this.state.accessToken).post('api/role', roleDetail)
            .then(res => {
                alert(res.data);
                   // window.location.href = '/roles';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            <div id="container" className="ContainerStyle">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1" style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Add New Role</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Role Id</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Role Id"
                                        onChange={this.handleRoleIdChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Role Id
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Description"
                                        onChange={this.handleDescriptionChange}
                                    />
                                     <Form.Control.Feedback type="invalid">
                                        Please provide Description
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                    <Form.Check type="checkbox" label="Authorized To Return"
                                        checked={this.state.authorizedToReturn}
                                        onChange={this.handleAuthorizedToReturnChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                    <Form.Check type="checkbox" label="Authorized To Override"
                                        onChange={this.handleAuthorizedToOverrideChange} />
                                </Form.Group>
                                <div className='form-group' style={{ marginTop: '20px',marginBottom: '30px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Add Role</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}