import React from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import httpCommon,{getHttpCommon} from '../../httpCommon'
import { loginRequest } from "../../authConfig";
import {validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'

export default class UpdateBrand extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)
        this.state = {
            validated: false,
            accessToken: ""
        }

        if(context.accountObj[0]!=undefined){
            this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
          }
          this.mslInstance = context.msalObj;
        
        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])
    }

    
    componentDidMount() {
        
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadBrandsList(response.accessToken);
        });

        
    }

    loadBrandsList(accessToken){
        var path = window.location.pathname.split('/');
        var brandId = path[2];

        getHttpCommon(accessToken).get('api/brand/' + brandId)
        .then(res => {
            const brand = res.data;
            this.setState({
                brandId: brand.brandId,
                name: brand.name,
                id: brand.id
            });
        }).catch(function (error) {
            alert(error);
        });
    }

    handleBrandIdChange = event => {
        this.setState({ brandId: event.target.value });
    }

    handleBrandNameChange = event => {
        this.setState({ name: event.target.value });
    }

    backBtnClick = (event) => {
        event.preventDefault();
        window.location.href = '/brands';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const brandDetail = {
                brandId: this.state.brandId,
                name: this.state.name,
                id: this.state.id
            };
            // var path = window.location.pathname.split('/');
            // var brandId = path[2];

            getHttpCommon(this.state.accessToken).put('api/brand/' + brandDetail.id, brandDetail)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/brands';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1" style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Update Brand</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}
                                className="form-inline justify-content-center">
                                <Form.Group>
                                    <Form.Label>Brand Id</Form.Label>
                                    <Form.Control
                                        required
                                        disabled
                                        type="text"
                                        placeholder="Brand Id"
                                        onChange={this.handleBrandIdChange}
                                        value={this.state.brandId}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Brand Id
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Brand name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Brand name"
                                        onChange={this.handleBrandNameChange}
                                        value={this.state.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Brand name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='form-group' style={{ marginTop: '20px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Update Brand</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}