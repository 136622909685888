import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class ShowRoles extends Component {

    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)

        this.state = {
            roles: [],
            accessToken: ""
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadRolesList(response.accessToken)
        });
    }

    loadRolesList(accessToken){
        getHttpCommon(accessToken).get('api/role')
        .then(res => {
            const roles = res.data;
            this.setState({ roles });
        }).catch(function (error) {
            alert(error);
        });
    }

    deleteRow(id, e) {
        var proceed = window.confirm("Are you sure you want to proceed to delete role " + id);
        if (proceed) {
            getHttpCommon(this.state.accessToken).delete('api/role/' + id)
                .then(res => {
                    alert(res.data);
                    window.location.reload(false);
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });

        } else {
            // alert("Don't Delete "+ id);
        }

    }

    render() {
        return (
            <div className="containerStyle">
                <div className="subHeaderContainer">
                    <div className="subTitle">Roles ({this.state.roles.length})</div>
                        <div className="subButtonSec">
                        <span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} variant="secondary" 
                    onClick={() => this.props.history.push('/createrole')}>Add a Role</span>
                         </div>
                </div>
                       <Table striped bordered hover>
                    <thead>
                        <tr className="tableHeader">
                            <th>Role Id</th>
                            <th>Description</th>
                            <th>Authorized To Return</th>
                            <th>Authorized To Override</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.roles.map((item) => (
                            <tr key={item.roleId} className="tableRow">
                                <td>{item.roleId}</td>
                                <td>{item.description}</td>
                                <td><input type="checkbox" checked={item.authorizedToReturn} /></td>
                                <td><input type="checkbox" checked={item.authorizedToOverride} /></td>
                                <td> <span className="btnNormal" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} onClick={() => this.props.history.push('/updaterole/' + item.roleId)}>Update</span>
                                <span className="btnNegative" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} variant="danger" onClick={(e) => this.deleteRow(item.roleId, e)} style={{ marginLeft: '10px' }}>Delete</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}