import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import httpCommon, {getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class ShowBrandMarketMaps extends Component {

    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)

        this.state = {
            brandMarketMap: [],
            accessToken: ''
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;
        
        this.loginUserPermission = context.userPermission
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadMarketList(response.accessToken)
        });
    }

    loadMarketList(accessToken){
        getHttpCommon(accessToken).get('api/brandmarketmap')
            .then(res => {
                const brandMarketMap = res.data;
                this.setState({ brandMarketMap });
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage);
            });
    }

    deleteRow(countryCode, brandId, countryGrp, e) {
        var proceed = window.confirm("Are you sure you want to proceed to delete Mapping of country " + countryCode + " for brand " + brandId);
        if (proceed) {
            getHttpCommon(this.state.accessToken).delete('api/brandmarketmap/' + countryCode + '/' + brandId + '/' + countryGrp)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/brandmarketmaps';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });

        } else {
            // alert("Don't Delete "+ id);
        }
    }

    render() {
        return (
            <div className="containerStyle">
                <div className="subHeaderContainer">
                <div className="subTitle">Brand Market Mapping ({this.state.brandMarketMap.length})</div>
                    <div className="subButtonSec">
                    <span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} variant="secondary" 
                    onClick={() => this.props.history.push('/createbrandmarketmap')}>Add a Brand Market Mapping</span>
                    </div>
                </div>
    
                 <Table striped bordered hover>
                    <thead>
                        <tr className="tableHeader">
                            <th>Country</th>
                            <th>Country Group</th>
                            <th>Brand Id</th>
                            <th>SLF</th>
                            <th>Best Selling Potential</th>
                            <th>Tax Calculation</th>
                            <th>Exclude Shipping Fee</th>
                            <th>Keep Or Send Default</th>
                            <th>Platform Type</th>
                            <th>Primary WH</th>
                            <th>Package Type</th>
                            <th>New Send Flow</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.brandMarketMap.map((item) => (
                            <tr key={item.country} className="tableRow">
                                <td>{item.country}</td>
                                <td>{item.countryGrp}</td>
                                <td>{item.brandId}</td>
                                <td><input type="checkbox" checked={item.slf} /></td>
                                <td><input type="checkbox" checked={item.bestSellingPotentialEnabled} /></td>
                                <td><input type="checkbox" checked={item.taxCalculationEnabled} /></td>
                                <td><input type="checkbox" checked={item.shippingFeeExcluded} /></td>
                                <td>{item.keepOrSendDefault}</td>
                                <td>{item.platformType}</td>
                                <td>{item.primaryWH}</td>
                                <td>{item.packageType}</td>
                                <td><input type="checkbox" checked={item.isSendFlowEnabled} /></td>
                                <td style={{ width:'400px'}}><span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} onClick={() => this.props.history.push('/updatebrandmarketmap/' + item.country + '/' + item.brandId + '/' + item.countryGrp)}>Update</span>
                                <span className="btnNegative"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} variant="danger" onClick={(e) => this.deleteRow(item.country, item.brandId, item.countryGrp, e)} style={{ marginLeft: '10px' }}>Delete</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}