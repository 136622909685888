import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import httpCommon, { getHttpCommon } from '../../httpCommon';
import { isWritePermission, validateRole } from '../../Constants/Utilities';
import UserContext from './../Context/SampleContext';

import { loginRequest } from "../../authConfig";
import LoadingSpinner from '../../LoadingSpinner';
import Pagination from '../../components/Pagination';
import Modal from 'react-bootstrap/Modal'
import UpdateStore from './UpdateStore'
import AddStore from './AddStore'

export default class ShowStores extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context)
    this.selectedStoreItem = "";
    this.selectedStoreId = "";

    this.state = {
      stores: [],
      accessToken: "",
      isLoading: false,
      filterInput: "",
      storeBase: [],
      currentPage: 1,
      viewStoreDialog: false,
      newStoreDialog: false,
    }

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail,
      this.inputDisable = true
    this.pageSize = 10
  }


  componentDidMount() {
    this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      console.log(response.accessToken);
      //callMsGraph(response.accessToken).then(response => setGraphData(response));
      this.setState({ accessToken: response.accessToken })
      this.loadStoreList(response.accessToken)
    });
  }
  

  //To load Store Details 
  loadStoreList(accessToken) {

    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/store'
    var isSuperAdmin = validateRole(this.loginUserRoles, ["HM_SADMIN"]);
    if (isSuperAdmin) {
      //[HM_SADMIN Role]: Load All Store items as it is super admin
      var routePath = 'api/store'
    }
    else {
      //[HM_ADMIN Role]: Load Store List by login user email 
      routePath = 'api/Store/storeByUserEmail/' + this.loginUserEmail;
    }

    getHttpCommon(accessToken).get(routePath)
      .then(res => {

        const stores = res.data;
        console.log("store",stores)
        this.setState({ stores });
        this.setState({ storeBase: stores })
        this.setState({ isLoading: false })
      }).catch(function (error) {
        this.setState({ isLoading: false })

        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage);

      });
  }

  //To Delete selected store in the list
  deleteRow(id, e) {
    var proceed = window.confirm("If you delete this store, the users attached to this store will also be deleted. Are you sure you want to proceed to delete store " + id);
    if (proceed) {
      getHttpCommon(this.state.accessToken, this.loginUserEmail).delete('api/store/' + id)
        .then(res => {
          alert(res.data);
          window.location.reload(false);
        }).catch(function (error) {
          var response = error.response;
          var errorMessage = response.data.detail;
          alert(errorMessage);
        });
    } else {
    }
  }

  handleFilterChange = (event) => {

    this.setState({ filterValue: event.target.value });
    this.setState({ filterInput: "" });

    if (event.target.value == "filter") {
      this.inputDisable = true
      this.setState({ stores: this.state.storeBase })
    }
    else {
      this.inputDisable = false
      this.setState({ stores: this.state.storeBase })
    }
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value.toLowerCase() })

    if (this.state.filterInput != "null" && this.state.filterInput != "undefined") {
      var filteredStore = ""
      switch (this.state.filterValue) {
        case 'storeId':
          filteredStore = this.state.storeBase.filter(function (store) {
            return store.storeId.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'storeName':
          filteredStore = this.state.storeBase.filter(function (store) {
            return store.storeName.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'country':
          filteredStore = this.state.storeBase.filter(function (store) {
            return store.country.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'brandName':
          filteredStore = this.state.storeBase.filter(function (store) {
            return store.brandName.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
      }
      this.setState({ stores: filteredStore });
    } else {
      alert("please select a valid filter")
    }
  }

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;
    return this.state.stores.slice(firstPageIndex, lastPageIndex);
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }
  handleCloseViewStore = () => {
    this.setState({ viewStoreDialog: false });
  }

  handleCloseNewStore= () => {
    this.setState({ newStoreDialog: false });
  }

  handleViewStore=(storeId, item)=> {
    this.selectedStoreItem = item;
    this.selectedStoreId = storeId;
    this.setState({ viewStoreDialog: true });
  }

  handleNewStore= () => {
    this.setState({ newStoreDialog: true });
  }

  handleSaveCallBack = (status) => {
    //alert(status)
    this.selectedStoreItem = null;
    this.selectedStoreId = "";
    this.setState({ viewStoreDialog: false });

    //TODO: relead the list
    //alert(this.selectedItem);
  }

  handleSaveNewCallBack = (status) => {
    //alert(status)
    this.selectedStoreItem = null;
    this.selectedStoreId = "";
    this.setState({ newStoreDialog: false });

    //TODO: relead the list
    //alert(this.selectedItem);
  }

  render() {
    const { stores, currentPage } = this.state;

    return (
      <div className="containerStyle">
        <div className="subHeaderContainer">
          <div className="subTitle">Stores ({this.state.stores.length})</div>
          <div className="subButtonSec">
               <select style={{ margin: '10px', padding: '2px' }}
              value={this.state.filterValue}
              onChange={this.handleFilterChange}>
              <option value="filter">Filter By</option>
              <option value="storeId">Store Id</option>
              <option value="storeName">Store Name</option>
              <option value="country">Country</option>
              <option value="brandName">Brand Name</option>
            </select>
            <input onChange={this.handleFilterText} disabled={this.inputDisable} value={this.state.filterInput} style={{ margin: '10px' }} placeholder="Search text here"></input>
            {validateRole(this.loginUserRoles, ["HM_SADMIN"]) &&
              <span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} variant="secondary"
              onClick={() => this.handleNewStore()}>Add a Store</span>}
         
          </div>
        </div>
        <Modal show={this.state.viewStoreDialog} className='ModalStyle'>
          <Modal.Body>
            <UpdateStore storeId = {this.selectedStoreId} callBackClose={this.handleCloseViewStore} saveCallback={this.handleSaveCallBack}/>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.newStoreDialog} className='ModalStyle'>
          <Modal.Body>
            <AddStore storeId = {this.selectedStoreId} callBackClose={this.handleCloseNewStore} saveCallback={this.handleSaveCallBack}/>
          </Modal.Body>
        </Modal>
        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :
          <Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>Store Id</th>
                <th>Store Name</th>
                <th>Country</th>
                <th>Country Group</th>
                <th>Brand Name</th>
                {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<th>Keep Or Send Default</th>:null}
                {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<th>Enable ORIS</th>:null}
                <th style={{ display: 'none'}}>Prefered Language</th>
                <th>Action</th>
              </tr>
            </thead>

            {this.state.stores.length > 0 ?
            <tbody>
              {this.currentTableData().map((item, index) => (
                <tr key={index} className="tableRow notranslate">
                  <td >{item.storeId}</td>
                  <td style={{ textAlign: 'left', width:'500px'}}>{item.storeName}</td>
                  <td>{item.country}</td>
                  <td>{item.countryGrp}</td>
                  <td>{item.brandName}</td>
                  {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<td>{item.keepOrSendDefault}</td>:null}
                  {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<td><input type="checkbox" checked={item.orisEnabled} /></td>:null}
                  {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<td style={{ display: 'none'}}>{item.preferedLanguage}</td>:null}
                  <td>
                    <table >
                      <tr className="tableRow_no">
                        <td>
                        <span className="btnNormal translate"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} 
                  onClick={() => this.handleViewStore(item.storeId, item)}>Update</span>
                        </td>
                        <td>
                        {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])==true?
                  <span className="btnNegative translate" variant="danger" onClick={(e) => this.deleteRow(item.storeId, e)} style={{ marginLeft: '10px' }}>Delete</span>
                  :null}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              ))}
              </tbody> : <p>Result Not Found. Please use some other Filter Option</p>}
          </Table>}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={stores.length}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
      </div>
    )
  }
}