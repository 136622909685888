import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getHttpCommon } from '../../httpCommon';
import {validateRole } from '../../Constants/Utilities';
import UserContext from '../Context/SampleContext';

import { loginRequest } from "../../authConfig";
import LoadingSpinner from '../../LoadingSpinner';
import Pagination from '../../components/Pagination';
import {sendPushNotification} from '../../components/FCMHelper';

import closeIcon from '../../images/close_icon.png'
import greenTick from '../../images/tick_green.png'
import redCross from '../../images/icon_failed.png'

import Modal from 'react-bootstrap/Modal'
import loadingGif from '../../images/loading-gif.gif'

export default class ShowORISTrans extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context)

    this.state = {
      orderInfo: [],
      accessToken: "",
      isLoading: false,
      filterInput: "",
      orderInfoBase: [],
      currentPage: 1,
      showOrderInfo: false,
      selOrderInfo:{},
      integrations:[],
      openProgessIndicator: false
    }

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail,
      this.inputDisable = true
    this.pageSize = 7


  }


  componentDidMount() {
    //this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      console.log(response.accessToken);
      this.setState({ accessToken: response.accessToken })
      //this.loadStoreList(response.accessToken)
      //this.loadStoreDeviceList("")
      this.setState({isLoading:false})
    });
  }

  //To load Store Details 
  searchORISTrans(orderId) {
    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/Retrigger/GetOrderIntegrations';
    var requestParam = {
      "orderId": orderId,
      "dateFrom": "2024-01-01 00:00:00",
      "dateTo": "2024-01-01 00:00:00",
      "storeId": this.state.storeId,
    }

    getHttpCommon(this.state.accessToken).post(routePath, requestParam)
      .then(res => {
        var orderInfo = res.data;
        this.setState({ orderInfo: orderInfo });
        this.setState({ orderInfoBase: orderInfo })
        this.setState({ isLoading: false })
      }).catch(function (error) {
        console.log("Fetch Transaction Error:" +error);
        alert("Unable to fetch transactions, please retry after sometime")
        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage + ", or Please enter a valid store ID");
      });
      
  }

  callPerformRetriggerAPI(transactionId, transactionDet) {
    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/Retrigger/PerformRetriggr';
    transactionDet = {
        "TransactionID": "1",
        "OrderId": "R_123_0011",
        "IntegrationName": "i081",
        "IntegrationStatus": "OIS"
      }

    getHttpCommon(this.state.accessToken).post(routePath, transactionDet)
      .then(res => {
        alert("Retrigger is successful");
        this.setState({ openProgessIndicator: false });
      }).catch(function (error) {
        alert("Retrigger is unscessful, please retry after sometime")
        this.setState({ openProgessIndicator: false });
      });
      
  }

  handleError(){
    alert("test")
    this.setState({ orderInfo: []});
    this.setState({ orderInfoBase: []})
  }

  handleFilterChange = (event) => {

    this.setState({ filterValue: event.target.value });
    this.setState({ filterInput: "" });

    if (event.target.value == "filter") {
      this.inputDisable = true
      this.setState({ stores: this.state.storeDeviceBase })
    }
    else {
      this.inputDisable = false
      this.setState({ stores: this.state.storeDeviceBase })
    }
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value })
  }

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;
    return this.state.orderInfo.slice(firstPageIndex, lastPageIndex);
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  handleViewOrderInfo =(orderId, selItem) => {
    this.setState({ selOrderInfo: selItem});
    this.setState({ showOrderInfo: true });
    this.setState({integrations:selItem.Integrations});
  }
  handleClose = () => {
    this.setState({ showOrderInfo: false });
    this.setState({ openProgessIndicator: false });
  }
  performRetrigger = (integrationDet, confirmNeeded=false) => {

    if(this.state.openProgessIndicator==true){
      alert("Please wait, retrigger in progress")
      return;
    }

    //alert("test")
    if(confirmNeeded==true){
      if (window.confirm('Are you sure you wish to Retry?')) 
      {
        this.setState({ openProgessIndicator: true });
        this.callPerformRetriggerAPI(integrationDet.TransactionID, integrationDet);
      }  
    }
    else{
      this.setState({ openProgessIndicator: true });
      this.callPerformRetriggerAPI(integrationDet.TransactionID, integrationDet);
    }
  }

  render() {
    const { orderInfo, currentPage } = this.state;

    return (
      <div className="containerStyle">
        <Modal show={this.state.showOrderInfo} className='ModalStyle'>
          <Modal.Header className="modelHeaderBG" >
            <Modal.Title>Order Process - Tracker (OrderID: {this.state.selOrderInfo.OrderId})</Modal.Title>
            <img src={closeIcon} className="closeIconStyle" onClick={(e) =>this.handleClose()}/>
           </Modal.Header>
          <Modal.Body>
            <table className="innerTable">
              <tr className="reportHeader">
                <td>Status</td>
                <td>Integration</td>
                <td>Action</td>
              </tr>
              {this.state.integrations.length > 0 ?
                  this.state.integrations.map((integration, index) => (
                    <tr key={index} className='tableRow'>
                      <td><img className={(integration.IntegrationStatus == "OIS")?"statusIcon green":"statusIcon red"}/></td>
                      <td>{integration.IntegrationName}</td>
                      <td>
                        <span className="btnNormal" 
                        onClick={() => this.performRetrigger(integration, (integration.IntegrationStatus == "OIS"))}>Retry</span>
                      </td>
                    </tr>
                  )) : <tr>
                      <td colSpan={6}><p className="errorMessage">Not Records Yet. Please enter a valid order ID and search</p></td>
                      </tr>}
                <tr>
                  <td colspan="3">
                    {this.state.openProgessIndicator ?
                    <div className="loadingDiv">
                      <img src={loadingGif} className='loadingGif'/>
                      <span className='loadingMesage'>Please wait.. Retrigger in progress..</span>
                    </div>:null}
                  </td>
                </tr>      
            </table>
          </Modal.Body>
        </Modal>
        <div className="searchBox">
            <div className="searchBoxLabel">Search ORIS</div>
            <div class="searchBoxContainer">
              <input className="storeIdBox" onChange={this.handleFilterText} disabled={false} value={this.state.filterInput} placeholder="Enter Order Number"></input>
              <span className="searchButton" onClick={() => this.searchORISTrans(this.state.filterInput)}>Search</span>
            </div>
        </div>
        <div className="subHeaderContainer">
          <div className="subTitle subTitleMore">ORIS Transactions ({this.state.orderInfo.length})</div>
          < div className="subButtonSec">
               <select style={{ margin: '10px', padding: '2px', display: 'none' }}
              value={this.state.filterValue}
              onChange={this.handleFilterChange}>
              <option value="filter">Filter By</option>
              <option value="storeId">Store Id</option>
              <option value="country">Country</option>
              <option value="brandName">Brand Name</option>
            </select>
           
            <Button disabled={!this.state.orderInfo.length >0} onClick={(e) => this.sendMessageToAll(e)} style={{ margin: '10px', display:'none' }}>Send Message to All</Button>
          </div>
        </div>

        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :
          <Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>Order Id</th>
                <th>Transaction ID</th>
                <th>No.Of Items</th>
                <th>Date Time</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            {this.state.orderInfo.length > 0 ?

            <tbody>
              {this.currentTableData().map((item, index) => (
                <tr key={index} className='tableRow'>
                  <td>{item.OrderId}</td>
                  <td>{item.TransactionId}</td>
                  <td>{item.NoOfItems}</td>
                  <td>{item.OrderDateTime}</td>
                  <td className={(item.IntegrationStatus=="Success")?"textColorGreen":"textColorRed"}>{item.IntegrationStatus}</td>
                  <td>
                      <div style={{ textAlign:'center' }}>
                        <span className="btnNormal"  disabled={false} 
                        onClick={() => this.handleViewOrderInfo(item.OrderId, item)}>View</span>
                      </div>  
                  </td>
                </tr>
              ))}
              </tbody> : <tr>
                  <td colSpan={6}><p className="errorMessage">Not Records Yet. Please enter a valid order ID and search</p></td>
                  </tr>}
          </Table>}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={orderInfo.length}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
      </div>
    )
  }
}