import React from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import httpCommon, {getHttpCommon} from '../../httpCommon'
import UserContext from '../Context/SampleContext'
import {validateRole } from '../../Constants/Utilities';

import { loginRequest } from "../../authConfig";

export default class UpdateUser extends React.Component {
    //import UserContext from './../Context/SampleContext'
    static contextType = UserContext;


    constructor(props,context) {
        super(props,context)

        this.userId = props.userId;
        this.storeId = props.storeId;

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
        this.loginUserEmail = context.userEmail
        this.loginUserName = context.userName

        this.state = {
          userId: '',
          firstName: '',
          lastName: '',
          email: '',
          passCode: '',
          passCodeValidTo: '',
          mappedToStore: '',
          roleId: '',
          roleValidFrom: '',
          roleValidTo: '',
          validated: false,
          roles: [],
          stores: [],
          accessToken: '',
          tempPasscode: '',
        }
    }

  componentDidMount() {
    
    var path = window.location.pathname.split('/');
    var userId = this.userId //path[2];
    var storeId = this.storeId //path[3];

    this.mslInstance.acquireTokenSilent({
        ...loginRequest,
        account: this.loginUserRoles
    }).then((response) => {
        console.log(response.accessToken);
        this.setState({ accessToken: response.accessToken })
        this.loadRoleList(response.accessToken);
        this.loadStoreList(response.accessToken);
        this.loadSelectedUSerDetail(userId, storeId, response.accessToken);
    });

   
  }
    
  //To load Role list in the dropdown 
  loadRoleList(accessToken){

      getHttpCommon(accessToken).get('api/Role')
      .then(res => {
        this.setState({
          roles: res.data
        });
      }).catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage);
      });

  }

  //To load store list in the dropdown
  loadStoreList(accessToken){

    //TODO: ***** Validate the ROLE and build the Route *****

    //[HM_SADMIN Role]: Load All Store items as it is super admin
    //var routePath = 'api/store' 
    
    //[HM_ADMIN Role]: Load Store List by login user email 
    var routePath = 'api/Store/'
    var isSuperAdmin = validateRole(this.loginUserRoles, ["HM_SADMIN"]);
    if (isSuperAdmin) {
        //[HM_SADMIN Role]: Load All Store items as it is super admin
        routePath = 'api/Store/';
    }
    else {
        //[HM_ADMIN Role]: Load Store List by login user email 
        routePath = 'api/Store/storeByUserEmail/' + this.loginUserEmail;
    }

    getHttpCommon(accessToken).get(routePath)
      .then(res => {
        this.setState({
          stores: res.data
        });
      }).catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage);
      });

  }

  //To load selected User details for update
  loadSelectedUSerDetail(userId, storeId, accessToken){
    getHttpCommon(accessToken).get('api/User/' + userId + '/' + storeId)
      .then(res => {
        const user = res.data;
        this.setState({
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          passCode: user.passCode,
          passCodeValidTo: user.passCodeValidTo,
          mappedToStore: user.mappedToStore,
          roleId: user.roleId,
          roleValidFrom: user.roleValidFrom,
          roleValidTo: user.roleValidTo
        });
      }).catch(function (error) {
        alert(error);
      });
  }

  handleUserIdChange = event => {
    this.setState({
      userId: event.target.value
    });
  }

  handleFirstNameChange = event => {
    this.setState({ firstName: event.target.value });
  }

  handleLastNameChange = event => {
    this.setState({ lastName: event.target.value });
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  }

  handlePassCodeChange = event => {
    this.setState({ passCode: event.target.value });
  }

  handleStoreChange = event => {
    this.setState({ mappedToStore: event.target.value });
  }

  handlePassCodeValidToChange = (event) => {
    this.setState({ passCodeValidTo: event.target.value });
  };

  handleRoleChange = event => {
    this.setState({ roleId: event.target.value });
  }
  handleRoleValidFromChange = (event) => {
    this.setState({ roleValidFrom: event.target.value });
  };

  handleRoleValidToChange = (event) => {
    this.setState({ roleValidTo: event.target.value });
  };

  backBtnClick = (event) => {
    event.preventDefault();
    //window.location.href = '/users';
    this.props.saveCallback("");
  };

  regeneratePasscode = (event) => {
    event.preventDefault();
    var proceed = window.confirm("Are you sure, you want to regenerate passcode?");
      if (proceed) {
        const userDetails = {
        userId: this.state.userId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        passCode: this.state.passCode,
        passCodeValidTo: new Date(this.state.passCodeValidTo).toISOString(),
        mappedToStore: this.state.mappedToStore,
        roleId: this.state.roleId,
        roleValidFrom: new Date(this.state.roleValidFrom).toISOString(),
        roleValidTo: new Date(this.state.roleValidTo).toISOString()
      };
      getHttpCommon(this.state.accessToken, this.loginUserEmail).post('api/User/regeneratebulkpass', [userDetails])
        .then(res => {
          const status = res.statusText
          if (status == "OK") {
            const result = res.data
            this.setState({ tempPasscode: result })
            //alert(result)
          } else {
            alert("Not able to Re-generate passcode \n Please use edit option to re-generate")
          }
        }).catch(function (error) {
          //var response = error.response;
          //var errorMessage = response.data.detail;
          // alert(errorMessage);
          // console.log(errorMessage)
        });
      }
  };

  sendPassCodeClick = (event) => {
    event.preventDefault();
    var path = window.location.pathname.split('/');
    var userId = this.userId; //path[2];
    getHttpCommon(this.state.accessToken, this.loginUserEmail).get('api/user/triggerpasscode/' + userId)
      .then(res => {
        alert("Passcode resent to the user");
      })
      .catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.message;
        alert(errorMessage);
      });
  };

  //To handle event to update selected user
  handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
    }
    else {
      event.preventDefault();

      const userDetails = {
        userId: this.state.userId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        passCode: this.state.passCode,
        passCodeValidTo: new Date(this.state.passCodeValidTo).toISOString(),
        mappedToStore: this.state.mappedToStore,
        roleId: this.state.roleId,
        roleValidFrom: new Date(this.state.roleValidFrom).toISOString(),
        roleValidTo: new Date(this.state.roleValidTo).toISOString()
      };
      var path = window.location.pathname.split('/');
      var userId = this.userId; //path[2];
      var storeId = this.storeId; //path[3];

      getHttpCommon(this.state.accessToken, this.loginUserEmail).put('api/user/' + userId + '/' + storeId, userDetails)
        .then(res => {
          alert(res.data);
          window.location.href = '/users';
          //TODO: Refresh the user by callback
          //console.log(userDetails)
          //this.props.saveCallback("success");
        })
        .catch(function (error) {
          var response = error.response;
          var errorMessage = response.data.detail;
          alert(errorMessage);
        });
    }
  }

  render() {
    return (
      <div id="container">
        <div>
          <div>
            <div style={{ marginTop: '20px', width: '100%'}}>
              <h4>Update User</h4>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                  <table style={{ marginTop: '20px', width: '100%'}} className="formInnerStyle">
                    <tr >
                      <td>
                        <Form.Group>
                          <Form.Label className="formFieldSpace">Operator Id</Form.Label>
                          <Form.Control
                            required
                            disabled
                            type="text"
                            placeholder="User Id"
                            value={this.state.userId}
                            onChange={this.handleUserIdChange}
                            style={{ backgroundColor: '#fff' }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide User Id
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Label className="formFieldSpace">Store</Form.Label>
                          <Form.Control as="select"
                            placeholder="Store"
                            required
                            value={this.state.mappedToStore}
                            onChange={this.handleStoreChange} >
                            <option value=''>--Select a Store--</option>
                            {this.state.stores.map((item) => (
                              <option value={item.storeId}>{item.storeName}</option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select Store
                          </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                      </tr>
                    <tr>
                      <td>
                        <Form.Group>
                            <Form.Label className="formFieldSpace">First name</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="First name"
                              value={this.state.firstName}
                              onChange={this.handleFirstNameChange}
                              className='notranslate'
                            />

                            <Form.Control.Feedback type="invalid">
                              Please provide First name
                            </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                      <Form.Group>
                          <Form.Label className="formFieldSpace">Role</Form.Label>
                          <Form.Control as="select"
                            placeholder="Role"
                            required
                            value={this.state.roleId}
                            onChange={this.handleRoleChange} >
                            <option value=''>--Select a Role--</option>
                            {this.state.roles.map((item) => (
                              <option value={item.roleId}>{item.description}</option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select Role
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      <Form.Group >
                          <Form.Label className="formFieldSpace">Last name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Last name"
                            value={this.state.lastName}
                            onChange={this.handleLastNameChange}
                            className='notranslate'
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide Last name
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                      <Form.Group>
                          <Form.Label className="formFieldSpace">Role Valid From</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            placeholder="RoleValidFrom"
                            required
                            value={this.state.roleValidFrom}
                            onChange={this.handleRoleValidFromChange} />
                          <Form.Control.Feedback type="invalid">
                            Please select Role Valid From
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      <Form.Group >
                          <Form.Label className="formFieldSpace">Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            className='notranslate'
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide Email
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>
                      <Form.Group>
                          <Form.Label className="formFieldSpace">Role Valid To</Form.Label>
                          <Form.Control type="datetime-local"
                            placeholder="RoleValidTo"
                            required
                            value={this.state.roleValidTo}
                            onChange={this.handleRoleValidToChange} />
                          <Form.Control.Feedback type="invalid">
                            Please select Role Valid To 
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group>
                          <Form.Label className="formFieldSpace">Passcode Valid To</Form.Label>
                          <Form.Control
                            type="datetime-local"
                            placeholder="Passcode"
                            required
                            value={this.state.passCodeValidTo}
                            onChange={this.handlePassCodeValidToChange} />
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          Please select Passcode Valid To
                        </Form.Control.Feedback>
                      </td>
                      <td>
                      {this.state.tempPasscode!= ""?
                      <Form.Group >
                          <Form.Label className="formFieldSpace">Temporary Passcode</Form.Label>
                          <Form.Control
                            required
                            disabled
                            type="text"
                            placeholder="Temp passcode"
                            value={this.state.tempPasscode}
                            style={{ backgroundColor: '#fff' }}
                          />
                        </Form.Group>: null}
                      </td>
                    </tr>
                  </table>
                <div style={{ marginTop: '20px', width: '100%', textAlign:"center"}}>
                  <span class="customLinkStyle" onClick={this.regeneratePasscode}>Regenerate temporary passcode</span>
                </div>
                <div style={{ marginTop: '30px',marginBottom: '20px', textAlign:"center"}}>
                    <button className="btnNormalBkp"   type="submit" style={{ marginTop: '10px' }}>Save</button>
                    <span className="btnNormal"   type="submit"  style={{ marginTop: '10px', marginLeft: '10px' }} onClick ={this.props.callBackClose}>Close</span>
                    <span className="btnNormalBkp"   type="submit" onClick={this.sendPassCodeClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Email Passcode</span>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}