import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import pdf from './Onboarding Brand.pdf'
import pdf1 from './Onboarding Market.pdf'
import pdf2 from './Onboarding Stores.pdf'
import pdf3 from './Onboarding Users.pdf'
import accessreq from './AccessRequest.pdf'
import howtousedoc from './howtosm.pdf'
import UserContext from './Context/SampleContext'
import {validateRole} from '../Constants/Utilities';
import image1 from '../images/index1.jpeg';
import image2 from '../images/index2.jpeg';
import image3 from '../images/index3.jpg';
import image4 from '../images/index4.jpg';
import close from '../images/close.png';
import check from '../images/check.png';
import Collapsible from '../components/Collapsible'
import imgQ1 from '../images/Help/q1.png'

import Scr01 from '../images/Help/Scr01.png'
import Scr02 from '../images/Help/Scr02.png'
import Scr03 from '../images/Help/Scr03.png'
import Scr04 from '../images/Help/Scr04.png'
import Scr05 from '../images/Help/Scr05.png'
import Scr06 from '../images/Help/Scr06.png'
import Scr07 from '../images/Help/Scr07.png'
import Scr08 from '../images/Help/Scr08.png'
import Scr09 from '../images/Help/Scr09.png'
import Scr10 from '../images/Help/Scr10.png'
import Scr11 from '../images/Help/Scr11.png'
import Scr12 from '../images/Help/Scr12.png'
import Scr13 from '../images/Help/Scr13.png'
import Scr14 from '../images/Help/Scr14.png'
import Scr15 from '../images/Help/Scr15.png'

export default class Home extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);
    console.log("Homepage=====>>>>>>", props);
    this.loginUserEmail = context.userEmail;
    this.loginUserName = context.userName;
    this.loginUserRoles = context.userRoles;
    sessionStorage.IsConfigErrorShown = "false"

    this.state={
      faqData: []
    }
  }

  componentDidMount() {
    var FAQData = [{title:"1. Configuration Error", desc:"", imgsrc:Scr01, doclink:""},
      {title:"2. Invalid login issue – ORIS 3.1 app", desc:"", imgsrc:Scr02, doclink:""},
      {title:"3. Passcode is expired", desc:"", imgsrc:Scr03, doclink:""},
      {title:"4. Device is Locked error message", desc:"", imgsrc:Scr04, doclink:""},
      {title:"5. ORIS 3.1 App is freezing", desc:"", imgsrc:Scr05, doclink:""},
      {title:"6. Role is expired message", desc:"", imgsrc:Scr06, doclink:""},
      {title:"7. Store manager - Unable to see store details  ", desc:"", imgsrc:Scr07, doclink:""},
      {title:"8. Change store mapping / Store is wrongly mapped", desc:"", imgsrc:Scr08, doclink:""},
      {title:"9. Article Kimble/price tag is missing / unable to handle item", desc:"", imgsrc:Scr09, doclink:""},
      {title:"10. Unable to scan QR code in ORIS app on Smartphone", desc:"", imgsrc:Scr10, doclink:""},
      {title:"11. User needs access to ORIS3 / ORIS app / ORIS Admin portal", desc:"User that access to the ORIS3 App is managed by the store manager in the ORIS Admin portal Note: ORIS Admin portal instructions are available on the ORIS Admin portal page", imgsrc:"", doclink:""},
      {title:"12. ORIS app is missing or not opening on Smartphone", desc:"", imgsrc:Scr11, doclink:""},
      {title:"13. Unable to scan QR code in ORIS app on Smartphone", desc:"", imgsrc:Scr12, doclink:""},
      {title:"14. ORIS app is stuck on loading / time out issue / app is crashing", desc:"", imgsrc:Scr13, doclink:""},
      {title:"15. Item is physically missing after return in ORIS app", desc:"Store user that if the item is physically missing and therefore can’t be handled by store, it will stay in the list of pending items within the ORIS App until it is cleaned out after some time, depending on accounting routines", imgsrc:"", doclink:""},
      {title:"16. User cannot close or send box", desc:"", imgsrc:Scr14, doclink:""},
      {title:"17. Customer missing refund and/or confirmation mail for the return", desc:"1.	Store user to ask Customer to contact Customer Service. Customer refund issues cannot be solved by the store.  Note: If the return was done recently in the same store user can check in the ORIS app if the return item has been registered as returned. ", imgsrc:"", doclink:""},
      {title:"18. How to connect to a wifi network on iOS Tablet", desc:"", imgsrc:Scr15, doclink:""},
      {title:"", desc:"", imgsrc:"", doclink:""},
    ]

    this.setState({faqData: FAQData});
  }

  render() {

    return (
      <center>
      <Container className="containerStyleHome">
        <table className="homeImageContainer">
          <tr style={{display:"visible"}}>
            <td className= 'imageCellStyle'>
            <img src={image1} className="homeImageStyle"/>
            <img src={close} className="cornerImageStyle"/>
            </td>
            <td className= 'imageCellStyle'>
            <img src={image3} className="homeImageStyle"/>
            <img src={check} className="cornerImageStyle"/>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div className="container">
                <div className="row">
                  <div >
                    <header className="text-center">
                      <h3>Need Help?</h3>
                    </header>
                    <div className="collapsecontent">
                    {this.state.faqData.map((item) => (
                           <Collapsible title={item.title}>
                             <p>
                              
                              {item.doclink.length>0?
                              <span>Follow Instructions in this link <a href={item.doclink} target="_blank" title="Onboarding Brands" rel="noreferrer">Help Document</a></span>
                              :""}
                             </p>
                             <p>
                             {item.desc}
                             </p>
                             <p>
                             <img src={item.imgsrc} width="70%"/>
                             </p>
                           </Collapsible>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: 'center', marginTop: '20px'}}>
              <h4 style={{ textAlign: 'center', marginTop: '20px' }}>More Help?</h4>
              <a href={howtousedoc} target="_blank" title="Onboarding Brands" rel="noreferrer">1. Admin Portal Guide for store managers</a>
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: 'center'}}>
              <a style={{ paddingBottom: '100px'}} href={accessreq} target="_blank" title="Onboarding Brands" rel="noreferrer">2. Admin portal Access Request</a>
            </td>
          </tr>
        </table>
        <ul>
        {validateRole(this.loginUserRoles, ["HM_SADMIN","HM_PADMIN"]) && <li><a style={{ display: 'none' }} href={howtousedoc} target="_blank" title="Onboarding Brands" rel="noreferrer">Admin Portal Guide for store managers</a></li>}
        {validateRole(this.loginUserRoles, ["HM_SADMIN","HM_PADMIN"]) && <li><a style={{ display: 'none' }} href={pdf1} target="_blank" title="Onboarding Country" rel="noreferrer">Onboarding Country/Market</a></li>}
        {validateRole(this.loginUserRoles, ["HM_ADMIN", "HM_SADMIN","HM_PADMIN"]) && <li><a style={{ display: 'none' }} href={pdf2} target="_blank" title="Onboarding Stores" rel="noreferrer">Onboarding Stores</a></li>}
        {validateRole(this.loginUserRoles, ["HM_ADMIN", "HM_SADMIN","HM_PADMIN"]) && <li><a style={{ display: 'none' }} href={pdf3} target="_blank" title="Onboarding Users" rel="noreferrer">Onboarding Users</a></li>}
        </ul>
       
      </Container>
      </center>
    )
  }
}