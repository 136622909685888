import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class ShowMarkets extends Component {
  
  static contextType = UserContext;
  constructor(props,context) {
    super(props,context)

    this.state = {
      markets: []
    }

    this.loginUserRoles = []
    if(context.accountObj[0]!=undefined){
      this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;

    this.loginUserPermission = context.userPermission
  }

  componentDidMount() {
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      console.log(response.accessToken);
        //callMsGraph(response.accessToken).then(response => setGraphData(response));
        this.setState({ accessToken: response.accessToken })
        this.loadMarketList(response.accessToken)
    });
  }

  loadMarketList(accessToken){
    getHttpCommon(accessToken).get('api/market')
    .then(res => {
      const markets = res.data;
      this.setState({ markets });
    }).catch(function (error) {
      alert(error);
    });
  }

  deleteRow(id, e) {
    var proceed = window.confirm("Are you sure you want to proceed to delete market " + id);
    if (proceed) {
      getHttpCommon(this.state.accessToken).delete('api/market/' + id)
        .then(res => {
          alert(res.data);
          window.location.reload(false);
        }).catch(function (error) {
          var response = error.response;
          var errorMessage = response.data;
          alert(errorMessage);
        });

    } else {
      // alert("Don't Delete "+ id);
    }
  }

  render() {
    return (
      <div className="containerStyle">
        <div className="subHeaderContainer">
            <div className="subTitle">Markets ({this.state.markets.length})</div>
                <div className="subButtonSec">
                <span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN"])} variant="secondary"
              onClick={() => this.props.history.push('/createmarket')}>Add a Market</span>
                </div>
          </div>
         <Table striped bordered hover>
          <thead>
            <tr className="tableHeader">
              <th>Country</th>
              <th>Manager override required</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {this.state.markets.map((item) => (
              <tr key={item.country} className="tableRow">
                <td>{item.country}</td>
                <td><input type="checkbox" checked={item.managerOverrideRequired} /></td>
                <td> <span className="btnNormal" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN"])} onClick={() => this.props.history.push('/updatemarket/' + item.country)}>Update</span>
                <span className="btnNegative" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN"])} variant="danger" onClick={(e) => this.deleteRow(item.country, e)} style={{ marginLeft: '10px' }}>Delete</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}