import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const LoadingSpinner = (isLoading, padding=150) => {
  return (
    <div style={{ paddingTop: padding}}>
      <ClipLoader color={"#316CF4"} loading={isLoading} cssOverride={true} size={30} />
    </div>

  )
}

export default LoadingSpinner;