import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getHttpCommon} from '../../httpCommon';
import {validateRole } from '../../Constants/Utilities';
import SampleContext from '../Context/SampleContext';

import { loginRequest } from "../../authConfig";
import LoadingSpinner from '../../LoadingSpinner';
import Pagination from '../../components/Pagination';
import QRCode from "react-qr-code";
import { set } from 'date-fns';

export default class PendingItems extends Component {
  static contextType = SampleContext;

  constructor(props, context) {
    super(props, context)

    var { storeDet } = context;

    this.state = {
      pendingItemsList: [],
      accessToken: "",
      isLoading: false,
      filterInput: "",
      pendingItemsBase: [],
      currentPage: 1,
      storeId: "",
      openDialogQR: false,
      itemGTIN:"",
      itemNumber:"",
    }

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail,
      this.inputDisable = true
    this.pageSize = 15
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      console.log(response.accessToken);
      this.setState({ accessToken: response.accessToken })
      //this.loadStoreList(response.accessToken)
      //this.loadPendingItemList("")
      this.setState({isLoading:false})
    });
  }
  handleClose = () => {
    this.setState({ openDialogQR: false });
  }
  handleOpen = (GTIN, ItemNumber) => {  
    this.setState({ openDialogQR: true });
    this.setState({ itemGTIN: GTIN });
    this.setState({ itemNumber: ItemNumber });
  }  



  //To load Store Details 
  loadPendingItemList(storeId) {

    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/Box/GetPendingItems/' + storeId + "/" + this.loginUserEmail;

    this.setState({storeId: storeId});
    this.setState({ pendingItemsList: [] });

    getHttpCommon(this.state.accessToken).get(routePath)
      .then(res => {
        const pitemList = res.data;
        this.setState({ pendingItemsList: pitemList });
        this.setState({ pendingItemsBase: pitemList })
        this.setState({ isLoading: false })
      }).catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.detail;
        //this.handleError();
        //alert(errorMessage + ", or Please enter a valid store ID");
        alert("No data found for the store");
      });
  }

  handleError(){
    alert("test")
    this.setState({ pendingItemsList: []});
    this.setState({ pendingItemsBase: []})
  }

  //To Delete selected store in the list
  showItemQR(item) {
    //alert(JSON.stringify(item));
    if(item.gtin!=null && item.gtin.length >0){    
      var proceed = window.confirm("Please use ORIS 3.1 app item handling screen to handle this item. ");
      if (proceed) {
        //Write code here to show ITEM QR code
        this.handleOpen(item.gtin, item.orderLineItemId);
      } 
      else {
      }
    }
    else{
      alert("Unfortunately, this item dont have GTIN number, Please contact ORIS tech support team or Create Incident.");
    }
  }

  handleFilterChange = (event) => {

    this.setState({ filterValue: event.target.value });
    this.setState({ filterInput: "" });

    if (event.target.value == "filter") {
      this.inputDisable = true
      this.setState({ stores: this.state.pendingItemsBase })
    }
    else {
      this.inputDisable = false
      this.setState({ stores: this.state.pendingItemsBase })
    }
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value })
  }

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;
    return this.state.pendingItemsList.slice(firstPageIndex, lastPageIndex);
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  handleStoreChange = (event) => {
    if (event.target.value != "filter") {
      this.loadPendingItemList(event.target.value)
      this.setState({ storeId: event.target.value });
    }
  }

  render() {
    const { pendingItemsList, currentPage } = this.state;

    return (
      <div className="containerStyle">
        <div className="searchBox" style={{ width: '250px' }}>
            <div className="searchBoxLabel"  style={{ display: 'none' }}>Search Pending Items</div>
            <div class="searchBoxContainer" style={{ display: 'none' }}>
              <input className="storeIdBox" onChange={this.handleFilterText} disabled={false} value={this.state.filterInput} placeholder="Enter Store ID"></input>
              <span className="searchButton" onClick={() => this.loadPendingItemList(this.state.filterInput)}>Search</span>
            </div>
            <span>
              <SampleContext.Consumer>
                  {storeDet =>{
                    if(storeDet.userStoreList != null)
                    {
                          return <Form.Group>
                        <Form.Label><b>Choose store </b></Form.Label>
                        <Form.Control as="select" placeholder="Store" required 
                        value={this.state.storeId} 
                        onChange={this.handleStoreChange} 
                          style={{ backgroundColor: '#fff' }} >
                            <option value=''>--Select a Store--</option>
                            {storeDet.userStoreList.map((store, index) => (
                                store.isSendFlowEnabled==true?
                                <option key={index} value={store.storeId}>{store.storeId}</option>:null
                                
                            ))}
                            
                        </Form.Control>
                    </Form.Group>
                    }
                  }}
              </SampleContext.Consumer>
            </span>
        </div>
        <div className="subHeaderContainer">
          <div className="subTitle subTitleMore">Pending Items in {this.state.storeId} ({this.state.pendingItemsList.length})</div>
          < div className="subButtonSec">
               <select style={{ margin: '10px', padding: '2px', display: 'none' }}
              value={this.state.filterValue}
              onChange={this.handleFilterChange}>
              <option value="filter">Filter By</option>
              <option value="storeId">Store Id</option>
              <option value="country">Country</option>
              <option value="brandName">Brand Name</option>
            </select>
           
            <Button disabled={!this.state.pendingItemsList.length >0} onClick={(e) => this.sendMessageToAll(e)} style={{ margin: '10px', display:'none' }}>Send Message to All</Button>
          </div>
          <Modal show={this.state.openDialogQR} className='ModalStyle' 
          >
            <Modal.Header className="modelHeaderBG size16" >
              <Modal.Title>Scan this QR for the item ({this.state.itemNumber})</Modal.Title>
              <span className="closeIconStyle2 size16" onClick={() => this.handleClose()}>&times;</span>
            </Modal.Header>
            <Modal.Body>
              <div className= "errContent">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                  value={this.state.itemGTIN}
                  viewBox={`0 0 256 256`}
                  />
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :
          <Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>Box ID</th>
                <th>Item Number</th>
                <th>Article Desc</th>
                <th>Color</th>
                <th>Size</th>
                <th>Price</th>
                <th>Show Item QR</th>
              </tr>
            </thead>

            {this.state.pendingItemsList.length > 0 ?

            <tbody>
              
              {this.currentTableData().map((item, index) => (
                <tr key={index} className='tableRow notranslate'>
                  <td>{item.boxId}</td>
                  <td>{item.orderLineItemId}</td>
                  <td style={{ minWidth: '280px'}}>{item.articleDesc}</td>
                  <td>{item.lineItemColor}</td>
                  <td>{item.lineItemSize}</td>
                  <td>{item.itemOnlinePrice}</td>
                  <td style={{ minWidth: '200px'}}>
                  <span className="unblockBtn translate" onClick={(e) => this.showItemQR(item)}>Show QR</span>
                 </td>
                </tr>
              ))}
              </tbody> : <tr>
                  <td colSpan={6}><p className="errorMessage">Not Records Yet. Please enter store id and search</p></td>
                  </tr>}
          </Table>}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={pendingItemsList.length}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
      </div>
    )
  }
}