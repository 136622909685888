import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission, validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class AddCountryManager extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)

        this.state = {
            validated: false,
            brands: [],
            markets: []
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
        this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])
    }
    
    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadBrandsList(response.accessToken)
            this.loadMarketList(response.accessToken)
        });
    }
    
    loadBrandsList(accessToken){
        getHttpCommon(accessToken).get('api/Brand')
        .then(res => {
            this.setState({
                brands: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }
    loadMarketList(accessToken){
        getHttpCommon(accessToken).get('api/Market')
        .then(res => {
            this.setState({
                markets: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    handleFirstNameChange = event => {
        this.setState({ firstName: event.target.value });
    }

    handleLastNameChange = event => {
        this.setState({ lastName: event.target.value });
    }

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    }

    handleCountryChange = event => {
        this.setState({ country: event.target.value });
    }

    handleBrandIdChange = event => {
        this.setState({ brandId: event.target.value });
    }

    backBtnClick = (event) => {
        event.preventDefault();
        window.location.href = '/countrymanagers';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const countryManagerDetail = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                country: this.state.country,
                brandId: this.state.brandId
            };

            getHttpCommon(this.state.accessToken).post('api/countrymanager', countryManagerDetail)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/countrymanagers';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1" style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Add New Country Manager</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}
                                className="form-inline justify-content-center">
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="First name"
                                        onChange={this.handleFirstNameChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide First name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Last name"
                                        onChange={this.handleLastNameChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Last name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Email"
                                        onChange={this.handleEmailChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Email
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required onChange={this.handleCountryChange} >
                                        <option value=''>--Select a Country--</option>
                                        {this.state.markets.map((item) => (
                                            <option value={item.country}>{item.country}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required onChange={this.handleBrandIdChange} >
                                        <option value=''>--Select a Brand--</option>
                                        {this.state.brands.map((item) => (
                                            <option value={item.brandId}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Brand
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='form-group' style={{ marginTop: '20px',marginBottom: '30px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Add Country Manager</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}