//import React from 'react'
import data from './data.json'

let userData = [];
let prefered_language=["EN","RO","MX"]
//let userEmail = "";

/*export default  Permissions = () => {
    const userClaims = data[0].user_claims
    console.log("userClaims"+userClaims)

    userClaims.forEach(element => {
      if(element.typ === "roles"){
       const permission = element.val
       console.log("permission-T"+ permission)
       return permission
      }
    })
}*/

export const isWritePermission = (contextValue)=>{
  return (contextValue === "HM_ADMIN"? false: true)
} 

export const setLoginUserDetail = (userDataArg)=>{
  userData = userDataArg
}
export const getLoginUserDetail = ()=>{
  return userData;
}

//To validate role and conditionally render UI elements
export const validateRole = (userRoles, rolesElement)=>{
  if(userRoles!=undefined && userRoles!=null){
    const contains = userRoles.some(element => rolesElement.includes(element));
    return contains;
  } 
  else{
    return true;
  }
} 

export const getLanguage = ()=>{
  return prefered_language
}