import React, { Component } from 'react'

export default class RouteNotFound extends Component {
    render() {
        return (
            <div className="containerStyle">
                <h5>404</h5>
               <h5> Page Not Found</h5> 
            </div>
        )
    }
}
