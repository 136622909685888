import axios from "axios";
import { Cookies } from 'react-cookie';

export default axios.create({
  baseURL: process.env.REACT_APP_OCP_APIM_URL,
  headers: {
    "Content-type": "application/json",
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    //"Access-Control-Allow-Origin": "*",
    //"Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS" 
  }
});

export const getHttpCommon=(access_token, userId)=>{
  let access_Token=access_token
  let login_user=userId
  return axios.create(
  {
    baseURL: process.env.REACT_APP_OCP_APIM_URL,
    headers: {
      "Content-type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "Authorization": `Bearer ${access_Token}`,
      "login_user":login_user
      //"Access-Control-Allow-Origin": "*",
      //"Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  })}
    

  export const testSAPCall=()=>{
    return axios.create(
    {
      baseURL: "https://wd-pd0.hm.com/sap/opu/odata/SAP/ZSTR_ORIS_SRV/GetBrandCountrySet(UserName=' ')?sap-client=100",
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        //"Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
        "Authorization": "Basic U1lTSFNTRTA3MDE6I0hNJlRjc0AxMjM0Iw==",
        //"login_user":login_user
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
    })}
// For common config
//axios.defaults.headers.post["Authorization"] = "Basic U1lTSFNTRTA3MDE6I0hNJlRjc0AxMjM0Iw==";
    
// const mainAxios = axios.create({
//     baseURL: 'https://some-domain.com/api/'
// });
    
// const customAxios = axios.create({
//     baseURL: 'https://some-custom-domain.com/api/'
// });
    
    
// export {
//   mainAxios,
//   customAxios
// };

// export default axios.create({
//   //baseURL: "https://login.windows.net/30f52344-4663-4c2e-bab3-61bf24ebbed8/oauth2/token",
//   //baseURL:"https://gateway-test.hapi.hmgroup.com/oris3-admin-portal/",
//   headers: {
//     "Content-type": "application/json",
//     "client_id":"d8d9f23f-5a1f-472d-8559-b817950f6014",
//     "client_secret":"bUU7Q~BR2TYHmDdN6G_ukTTgNhvX6Mlbg_0qb",
//     "resource":"fcfb10dc-d104-4cbc-9742-218c88606dc3"
//     //"Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
//    // "Access-Control-Allow-Origin": "http://localhost:3000",
//    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'   
//   }
// });