import React from 'react'
import Collapsible from "../../components/Collapsible.js";

export default class HelpComponent extends React.Component {

    constructor(props,context) {
        super(props,context)

        this.state = {
            accessToken: ""
        }
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <h1>ORIS 3.1 Help</h1>
            </div>
        )
    }
}