import React, { Component,useState} from 'react'
import Table from 'react-bootstrap/Table'
//import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import httpCommon, { getHttpCommon } from '../../httpCommon'
import Pagination from '../../components/Pagination';
import UserContext from './../Context/SampleContext'
import Modal from 'react-bootstrap/Modal'
import { isWritePermission, validateRole } from '../../Constants/Utilities'
import Dropdown from 'react-bootstrap/Dropdown';
import e from 'cors';
import { loginRequest } from "../../authConfig";
import { format } from 'date-fns'
import LoadingSpinner from '../../LoadingSpinner';
import CryptoJS from "crypto-js"
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {exportExcel, exportCSV, vaidateUserData, exportExcelMoreSheets} from "../../components/ExportAsFileUtil"
import Popup from 'reactjs-popup';
import excelTempalte from '../storeid.xlsx'
import Form from 'react-bootstrap/Form'
import {decryptAES} from '../../components/AESHelper'
import closeIcon from '../../images/close_icon.png'
import loadingGif from '../../images/loading-gif.gif'
import UserUser from './UpdateUser'
import AddUser from './AddUser'

export default class User extends Component {
  static contextType = UserContext;
  

  constructor(props, context) {
    super(props, context);
    this.pageSize = 50
    this.timerMin="00"
    this.timerSec="00"   
    this.selectedUserId=""; 
    this.selectedUserStoreId = '';

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;

    //State object to handle data in the UI
    this.state = {
      users: [],
      usersBase: [],
      currentPage: 1,
      openDialog: false,
      openViewDialog: false,
      openNewDialog: false,
      openSucessDialog: false,
      selectedFile: null,
      selectedFileName: "",
      filterValue: "",
      filterInput: "",
      accessToken: "",
      isLoading: false,
      isOnboarding: false,
      formData: null,
      emailId:  context.userEmail,
      currDataPage:1,
      totalRecords:0,
      arrowDirection: 'Next =>',
      multipleFactor:1,
      mypageSize:50,
      SearchKey: "All",
      SearchVal: "All",
      ProcessingTime: "00:00"
    }
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail
    this.loginUserName = context.userName
    this.inputDisable = true
    this.regenerateDisable = true
    this.dowloadPassDisable = true
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {

      this.setState({ accessToken: response.accessToken })
      //callMsGraph(response.accessToken).then(response => setGraphData(response));
      this.loadUsers(response.accessToken, false, "All", "All")
    });
  }


  //To delete selected user details in the list
  deleteUser(userId, storeId, e) {
    var proceed = window.confirm("Are you sure you want to proceed to delete the User (" + userId + ") from the store " + storeId);
    if (proceed) {
      getHttpCommon(this.state.accessToken, this.loginUserEmail).delete('api/user/' + userId + '/' + storeId)
        .then(res => {
          window.location.reload(false);

        }).catch(function (error) {
          console.log(JSON.stringify(error))
          var response = error.response;
          var errorMessage = response.data.detail;
          alert(errorMessage);

        });

    } else {
      // alert("Don't Delete "+ id);
    }
  }

  //To delete selected user details in the list
  showPasscode(userId, storeId, e) {
    var proceed = window.confirm("Are you sure you want to user Passcode? ");
    if (proceed) {
      //Create base64 string
      let encodedUserId = base64_encode(userId);
      getHttpCommon(this.state.accessToken).get('api/user/getpasscode/' + encodedUserId +"/" +storeId )
        .then(res => {
          //alert(base64_decode(res.data));
          var result = decryptAES(res.data, process.env.REACT_APP_AES_KEY)
          alert(result);
        }).catch(function (error) {
          alert(error.message);
        });

    } else {
      // alert("Don't Delete "+ id);
    }
  }

  loadNextUsersList()
  {
    var totalPages = this.state.totalRecords;
    var noofPages = parseInt(totalPages/this.pageSize) +1;
    this.state.currDataPage = this.state.currDataPage + (1 * this.state.multipleFactor);

    
    this.loadUsers(this.state.accessToken, false, this.state.SearchKey, this.state.searchVal)

    if(this.state.currDataPage>=noofPages){
      //alert("Maximum page is reached");
      this.setState({arrowDirection: '<= Prev'})
      this.setState({multipleFactor: -1})
      this.state.currDataPage = this.state.currDataPage-1;
      return;
    }
    else if(this.state.currDataPage<=1){
      //alert("Minimum page is reached");
      this.setState({arrowDirection: 'Next =>'})
      this.setState({multipleFactor: 1})
      this.state.currDataPage = this.state.currDataPage+1;
      return;
    }
  }

  updatePageSize = (event) => {
    console.log(event.target.value)
    //this.setState({ filterInput: event.target.value.toLowerCase() })
    this.pageSize = event.target.value
    this.setState({ mypageSize: event.target.value })
    //loadNextUsersList()
    this.setCurrentPage(1)
  }

  //To load user list
  loadUsers(accessToken, onlyPasscodeExpired, searchKey, searchVal) {
    //TODO: ***** Validate the ROLE and build the Route *****

    //[HM_SADMIN Role]: Load All Store items as it is super admin
    //var routePath = 'api/user' 

    //[HM_ADMIN Role]: Load Store List by login user email 
    var path = 'api/user'
    var isSuperAdmin = validateRole(this.loginUserRoles, ["HM_SADMIN"]);
    if (isSuperAdmin) {
      //[HM_SADMIN Role]: Load All Store items as it is super admin
      path = 'api/user';
    }
    else {
      //[HM_ADMIN Role]: Load Store List by login user email 
      path = 'api/user/' + this.loginUserEmail
    }
    if (onlyPasscodeExpired) {
      path = 'api/user/FilterByPinExpired/' + this.loginUserEmail
    }

    //Pagination parameter
    var pagingParam = '?PageNumber=' + this.state.currDataPage + '&PageSize=' + this.pageSize + 
    '&SearchKey=' + searchKey + '&SearchVal=' + searchVal; 
    //alert(pagingParam);

    getHttpCommon(accessToken).get(path + pagingParam)
   
      // httpCommon.get('api/user')
      .then(res => {
        const usersdata = res.data.userData;

        this.setState({currDataPage: res.data.currentPage})
       
        this.setState({totalRecords: res.data.totalRecords})

        //Object 1: This object will carry the actual response
        this.setState({ usersBase: usersdata });

        //Object 2: This object will be bind in UI
        this.setState({ users: usersdata });

        //Note: the offline filter option will filter data from Object 1 and assign result to Object 2
        this.setState({ isLoading: false })

      }).catch(function (error) {
        //this.setState({ isLoading: false })
        var response = error.response;
        alert(error);
        if (error.response.data != null) {
          var errorMessage = error.response.data.detail;
          //alert(errorMessage);
        }
        else {
          //alert(error);
        }
      });
  }

  /* setCurrentPage() {
    this.setState({ currentPage: 0 })
  }
  */

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;
    return this.state.users.slice(firstPageIndex, lastPageIndex);
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
    var totalPages = this.state.totalRecords;
    var noofPages = parseInt(totalPages/this.pageSize) +1;
    this.state.currDataPage = page
    this.loadUsers(this.state.accessToken, false,this.state.SearchKey, this.state.SearchVal)
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  handleCloseView(){
    this.setState({ openViewDialog: false });
  }

  handleCloseNew(){
    this.setState({ openNewDialog: false });
  }

  handleOpenView(userId, storeId, item){
    this.selectedItem = item;
    this.selectedUserStoreId = storeId;
    this.selectedUserId = userId;
    this.setState({ openViewDialog: true });
  }

  handleOpenNew(){
    this.setState({ openNewDialog: true });
  }

  hideOnboardingProgrss(){
    this.setState({ isOnboarding: false });
    this.setState({ selectedFile: null })
    this.setState({ selectedFileName: null })
    this.stopTimer();
  }

  handleCloseViewUser = () => {
    this.selectedUserStoreId = "";
    this.selectedUserId = "";
    this.setState({ openViewDialog: false });
  }
  
  handleCloseNewUser = () => {
    this.selectedUserStoreId = "";
    this.selectedUserId = "";
    this.setState({ openNewDialog: false });
  }

  handleSaveCallBack = (status) => {
    //alert(status)
    this.selectedUserStoreId = "";
    this.selectedUserId = "";
    this.setState({ openViewDialog: false });

    //TODO: relead the list
    //alert(this.selectedItem);
  }

  handleSaveNewCallBack = (status) => {
    //alert(status)
    this.selectedUserStoreId = "";
    this.selectedUserId = "";
    this.setState({ openNewDialog: false });

    //TODO: relead the list
    //alert(this.selectedItem);
  }

  handleFilterChange = (event) => {

    this.setState({ filterValue: event.target.value });
    var filteredUser = ""
    this.setState({ filterInput: "" });

    this.setState({ SearchKey: event.target.value[0].toUpperCase() + event.target.value.slice(1) });

    if (event.target.value == "expiryUser") {
      this.inputDisable = true
      filteredUser = this.state.usersBase.filter(function (user) {
        return user.isPasscodeExpired.toLowerCase() == "true"
      });
      this.setState({ users: filteredUser });
      this.regenerateDisable = filteredUser.length > 0 ? false : true

    } else if (event.target.value == "filter") {
      this.inputDisable = true
      this.setState({ users: this.state.usersBase })
    }
    else {
      this.inputDisable = false
      this.setState({ users: this.state.usersBase })
    }
  }

  filterForSuperAdmin(){

  }

  filterForNormalAdmin(){
    if (this.state.filterInput != "null" && this.state.filterInput != "undefined") {
      var filteredUser = ""
      //this.dowloadPassDisable = event.target.value.length > 0 ? false : true
      switch (this.state.filterValue) {
        case 'email':
          filteredUser = this.state.usersBase.filter(function (user) {
            return user.email.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'firstName':
          filteredUser = this.state.usersBase.filter(function (user) {
            return user.firstName.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'lastName':
          filteredUser = this.state.usersBase.filter(function (user) {
            return user.lastName.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'userId':
          filteredUser = this.state.usersBase.filter(function (user) {
            return user.userId.toLowerCase().match(event.target.value.toLowerCase())
          });
          break;
        case 'storeId':
          filteredUser = this.state.usersBase.filter(function (user) {
            return user.mappedToStore.toLowerCase().match(event.target.value.toLowerCase())
          });
          this.dowloadPassDisable = (filteredUser.length > 0 && event.target.value.length > 0)? false : true
          break;
      }
      this.setState({ users: filteredUser });
    } else {
      alert("please select a valid filter")
    }
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value.toLowerCase() })

    var filterByText = document.getElementById("filterbytext").value;

    console.log(filterByText);

    //To check its is super admin or normal admin
    var isSuperAdmin = validateRole(this.loginUserRoles, ["HM_SADMIN"]);

    isSuperAdmin = true;

    if (isSuperAdmin) {
      if(filterByText.length>3){
        var sel = document.getElementById("filterby");
        var text= sel.options[sel.selectedIndex].value;  
        this.setState({ SearchKey: text })
        this.setState({ SearchVal: filterByText })
        this.setState({users: []})
        this.setState({ usersBase: [] })
        this.setState({ currDataPage: 1 })
        this.setState({ totalRecords: 0 })
        this.loadUsers(this.state.accessToken, false, text, filterByText);
      }
      else if(filterByText.length<1){
        this.setState({ SearchKey: "All" })
        this.setState({ SearchVal: "All" })
        this.setState({users: []})
        this.setState({ usersBase: [] })
        this.setState({ currDataPage: 1 })
        this.setState({ totalRecords: 0 })
        this.loadUsers(this.state.accessToken, false, "All", "All");
      }
      
    }
    else {
      this.filterForNormalAdmin();
    }
    
  }

  startTimer = ()=>{
    var min = 0;
    var sec = 0;
    this.timerMin = "00";
    this.timerSec = "00";
    this.timer = setInterval(() => {
      sec++;
      if(sec>59){
        sec = 0;
        min++;
      }
      this.timerMin = min<10?"0"+min:min;
      this.timerSec = sec<10?"0"+sec:sec;
      this.setState({ProcessingTime: this.timerMin + ":" + this.timerSec})
    }, 1000);
  }
  stopTimer = ()=>{
    clearInterval(this.timer);
    this.setState({ProcessingTime: "00:00"})
  }
  

  callbackValidateBulkDataTemplate = (statusMessage) => {
    if(statusMessage=="success"){
      this.setState({ isOnboarding: true })
      this.startTimer()
        getHttpCommon(this.state.accessToken).post('api/User/userupload', this.state.formData)
            .then(res => {
              const result = res.data
              //console.log(result);
              if(Array.isArray(result))
              {
                result[0].data.map(userInfo => {
                  //userInfo.result = base64_decode(userInfo.result)
                  userInfo.result = decryptAES(userInfo.result, process.env.REACT_APP_AES_KEY)
                });

                exportExcelMoreSheets(result, "UserPasscode");
                
                this.setState({ openDialog: false })

                alert("Bulk user onboard is processed successfully. Please check the downloded excel file for modetails")

                this.hideOnboardingProgrss();
              }
              else
              {
                alert("Failure \n User's Not Onboarded \n Could you please try again later!!")
                this.hideOnboardingProgrss();
              }
            }).catch(function (error) {
              console.log(error)
              var response = error.response;
              var errorMessage = response.data.detail;
              alert(errorMessage);
              this.hideOnboardingProgrss();
              //console.log(errorMessage)
            });
      }
      else
      {
        alert(statusMessage)
      }
  }


  handleSubmit = (event) => {
    event.preventDefault()
    var selectedFileName = this.state.selectedFileName
    var selectedFile = this.state.selectedFile
    if (selectedFile != null && selectedFileName != null) {
      const formData = new FormData();
      formData.append("UserId", this.loginUserEmail);
      formData.append("files", this.state.selectedFile);
      //formData.append("FileName", this.state.selectedFileName);
      formData.append("Email", this.state.emailId);
      
      this.setState({formData: formData})


      //Validate excel file template
      vaidateUserData(this.state.selectedFile,this.callbackValidateBulkDataTemplate );
      
    } else {
      alert("Please upload valid user data")
    }

  }

  handleFileSelect = (event) => {
    // setSelectedFile(event.target.files[0])
    this.setState({ selectedFile: event.target.files[0] })
    this.setState({ selectedFileName: event.target.files[0].name })
  }

  handleSucessClose() {
    this.setState({ openSucessDialog: false })
  }

  handleEmailIdChange = event => {
    this.setState({ emailId: event.target.value });
}

  regeneratePasscode() {
    getHttpCommon(this.state.accessToken, this.loginUserEmail).post('api/User/regeneratebulkpass', this.state.users)
      .then(res => {
        const status = res.statusText
        if (status == "OK") {
          const result = res.data
          alert(result)
        } else {
          alert("Not able to Re-generate passcode \n Please use edit option to re-generate")
        }
      }).catch(function (error) {
        //var response = error.response;
        //var errorMessage = response.data.detail;
        // alert(errorMessage);
        // console.log(errorMessage)
      });
  }

  downloadPasscodes(){
    //form inputs
    //Managerid = ? NEED to fetch this value from the header section (Login user info)

    //Store id = ? Need to decide 
    let storeId = '';
    if(this.state.users.length>0){
      storeId= this.state.users[0].mappedToStore;
    }

    //User ids = need to collect it from selected users object array
    let userIds = this.state.users.map(user=> "'" + user.userId + "'").join(',')

    const passcodeRequest = {
      userIds: this.state.users.map(user=> user.userId),
      storeId: storeId,
      managerUserid: "",
      exportType: ""
    };

    console.log(userIds)
    
    if(storeId.length>0 && userIds.length>0){
      //Call the API
      //getHttpCommon(this.state.accessToken).get('api/User/downloadUserPasscodes?storeId=' + storeId + '&exportType=json&userIds=' + userIds)
      //getHttpCommon(this.state.accessToken).post('api/User/downloadUserPasscodes?storeId=' + storeId + '&exportType=json&userIds=' + userIds)

      getHttpCommon(this.state.accessToken).post('api/User/downloadUserPasscodes', passcodeRequest)
      .then(res => {
          const userPasscodes = res.data;
          //console.log(userPasscodes); //TODO: Remove this statement before push the code
          exportExcel(userPasscodes, "StoreUserData", "UserPasscode");
      }).catch(function (error) {
          console.log("Upload Error:" + error)
          alert("Technical Error.");
      });
    }
  }

  render() {
    const { users, currentPage } = this.state;
    return (
      <div className="containerStyle">
        <div>
          <div className="subTitle">Users (
              <input onChange={this.updatePageSize} disabled={false} value={this.state.mypageSize} style={{ margin: '0px',width:'90px',fontSize:'17px',border:'0px'}} placeholder="Records"></input>)
            <Button disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN"])} 
            onClick={() => this.loadNextUsersList()} 
            style={{ display: 'none'}}>({'Page:' + this.state.currDataPage}) {this.state.arrowDirection}</Button>
            </div>
          </div>
          <Pagination 
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={this.state.totalRecords}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
        <div className="subHeaderContainer">
          
          <div className="subButtonSec">

            <select style={{ margin: '10px', padding: '2px' }}
              value={this.state.filterValue} id="filterby"
              onChange={this.handleFilterChange}>
              <option value="filter">Filter By</option>
              <option value="email">Email</option>
              <option value="userId">UserId</option>
              <option value="firstName">First Name</option>
              <option value="lastName">Last Name</option>
              <option value="storeId">Store Id</option>
              <option value="expiryUser">Passcode Expired User</option>
            </select>
            <input onChange={this.handleFilterText} disabled={this.inputDisable} value={this.state.filterInput} style={{ margin: '10px' }} placeholder="Search text here" id="filterbytext"></input>
            {this.state.filterValue == "expiryUser" ?
              <span className="btnNormal2"  variant="secondary" disabled={this.regenerateDisable} style={{ margin: '10px' }}
                onClick={() => { if (window.confirm('Are you sure you wish to Re-generate passcode for all user?')) { this.regeneratePasscode() } }}>Re-Generate Passcode</span> :
              null}
            <button className="btnNormalBkp"  variant="secondary" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} 
            style={{ margin: '10px' }} onClick={() => this.setState({ openNewDialog: true })}>Add a User</button>
            <button className="btnNormal"  variant="secondary" disabled={!validateRole(this.loginUserPermission, 
              ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} style={{ margin: '10px' }} onClick={() => this.setState({ openDialog: true })}>Add multiple users</button>
            
            {this.state.filterValue == "disabled-storeId" ?
              <button className="btnNormal2"  variant="secondary" disabled={!(validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]) && !this.dowloadPassDisable)} style={{ margin: '10px' }} onClick={() => this.downloadPasscodes()}>Download User Passcodes</button>:
              null}
          </div>
         
        </div>
        <Modal show={this.state.openViewDialog} className='ModalStyle'>
          <Modal.Body>
            <UserUser userId={this.selectedUserId} storeId = {this.selectedUserStoreId} callBackClose={this.handleCloseViewUser} saveCallback={this.handleSaveCallBack}/>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.openNewDialog} className='ModalStyle'>
          <Modal.Body>
            <AddUser userId={this.selectedUserId} storeId = {this.selectedUserStoreId} callBackClose={this.handleCloseNewUser} saveCallback={this.handleSaveNewCallBack}/>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.openDialog} className='ModalStyle'>
          <Modal.Header className="modelHeaderBG" >
            <Modal.Title> Onboard Bulk Store Users</Modal.Title>
            {this.state.isOnboarding ?
            null:<img src={closeIcon} className="closeIconStyle" onClick={(e) =>this.handleClose()}/>}
          </Modal.Header>
          <Modal.Body >
            <div style={{ marginBottom: '20px'}}>
              <a href={excelTempalte} target="_blank">Download Excel File Template</a>
            </div>
            <form onSubmit={this.handleSubmit} style={{ marginBottom: '20px', display:"flex"}}>
            <Form style={{ marginBottom: '20px', display:'none'}}>
            <Form.Group>
                  <Form.Label>Email ID to send this report</Form.Label>
                  <Form.Control
                      required
                      type="text"
                      placeholder="Email Id"
                      value={this.state.emailId}
                      onChange={this.handleEmailIdChange}
                  />
                  <Form.Control.Feedback type="invalid">
                      Please provide email Id
                  </Form.Control.Feedback>
              </Form.Group>
              </Form>
              <input type="file" onChange={this.handleFileSelect} accept=".xlsx, .xls" disabled = {this.state.isOnboarding ?true:false}/>
              <input type="submit" value="Start Onboard" disabled = {this.state.isOnboarding ?true:false}/>
            </form>
            {this.state.isOnboarding ?
            <div className="loadingDiv">
              <img src={loadingGif} className='loadingGif'/>
              <span className='loadingMesage'>Please wait.. Onboarding in progress..({this.state.ProcessingTime})</span>
            </div>:null}
          </Modal.Body>
        </Modal>
        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :

          (<Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>User Id</th>
                <th>Store</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Passcode Valid To</th>
                <th>Action</th>
              </tr>
            </thead>

            {this.state.users.length > 0 ?
              <tbody className="notranslate">
                {this.state.users.map((item, index) => (
                  <tr key={index} className="tableRow">
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{item.userId}</td>
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{item.mappedToStore}</td>
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{item.firstName}</td>
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{item.lastName}</td>
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{item.email}</td>
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{item.roleName}</td>
                    <td style={item.isPasscodeExpired.toLowerCase() == "true" ? { color: '#C70039' } : { color: 'black' }}>{format(new Date(item.passCodeValidTo), 'dd-MM-yyyy')}</td>
                    <td>
                      <div style={{ display: 'flex' }}>
                        <span className="btnNormal translate"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} onClick={() => this.handleOpenView(item.userId,item.mappedToStore, item)}>View</span>
                        <span style={{ display: 'none' }} className="btnNormal translate"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} onClick={() => this.props.history.push('/updateuser/' + item.userId + '/' + item.mappedToStore)}>View</span>
                        {validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN","HM_ADMIN"])==true?<span className="btnNormal"   variant="danger" onClick={(e) => this.deleteUser(item.userId, item.mappedToStore, e)} style={{ marginLeft: '10px' }}>Delete</span>:null}
                        {item.isFirstTimePasscode?<span className="btnNormalBkp translate"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} variant="secondary" onClick={(e) => this.showPasscode(item.userId, item.mappedToStore, e)} style={{ marginLeft: '10px' }}>Show passcode</span>:null}
                      </div>

                    </td>
                  </tr>
                ))}
              </tbody> : <p>Result Not Found. Please use some other Filter Option</p>}
          </Table>)}
        
      </div>
    )
  }
}
