import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import httpCommon, {getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";
import Multiselect from 'multiselect-react-dropdown';

export default class AddBrandMarketMap extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)
        this.state = {
            validated: false,
            slf: false,
            bestSellingPotentialEnabled: false,
            taxCalculationEnabled: false,
            shippingFeeExcluded: false,
            keepOrSendDefault: "Keep",
            platformType:"",
            primaryWH:"",
            packageType:"",
            packageLength:0,
            packageWidth:0,
            packageHeight:0,
            isSendFlowEnabled:false,
            brands: [],
            markets: [],
            accessToken: '',
            integrations: [],
            selectedIntegratoins: [],
            WHIntegration: ''
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;
        
        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])

        this.loginUserEmail = context.userEmail
        this.loginUserName = context.userName
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadBrandsList(response.accessToken)
            this.loadMarketsList(response.accessToken)
            this.loadIntegrations(response.accessToken)
        });
    }

    loadBrandsList(accessToken){
        getHttpCommon(accessToken).get('api/Brand')
        .then(res => {
            this.setState({
                brands: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    loadMarketsList(accessToken){
        getHttpCommon(accessToken).get('api/Market')
            .then(res => {
                this.setState({
                    markets: res.data
                });
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage);
            });
    }

    loadIntegrations(accessToken){
        getHttpCommon(accessToken).get('api/BrandMarketMap/GetMarketIntegrations')
        .then(res => {
            this.setState({
                integrations: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    handleCountryChange = event => {
        this.setState({ country: event.target.value });
    }

    handleCCNameChange = event => {
        this.setState({ countryGrpName: event.target.value });
    }

    handleBrandIdChange = event => {
        this.setState({ brandId: event.target.value });
    }

    handleDummyWarehouseIdChange = event => {
        this.setState({ dummyWarehouseId: event.target.value });
    }

    handleSlfChange = event => {
        this.setState({ slf: event.target.checked });
    }

    handleBspEnabledChange = event => {
        this.setState({ bestSellingPotentialEnabled: event.target.checked });
    }

    handleTaxCalEnabledChange = event => {
        this.setState({ taxCalculationEnabled: event.target.checked });
    }

    handleShippingFeeEnabledChange = event => {
        this.setState({ shippingFeeExcluded: event.target.checked });
    }

    handleKeepOrSendDefaultChange = (event) => {
        this.setState({ keepOrSendDefault: event.target.value });
    };

    handleplatformTypeChange = (event) => {
        this.setState({ platformType: event.target.value });
    };
    handleprimaryWHChange = (event) => {
        this.setState({ primaryWH: event.target.value });
    };
    handlepackageTypeChange = (event) => {
        this.setState({ packageType: event.target.value });
    };
    handlepackageLengthChange = (event) => {
        this.setState({ packageLength: event.target.value });
    };
    handlepackageWidthChange = (event) => {
        this.setState({ packageWidth: event.target.value });
    };
    handlepackageHeightChange = (event) => {
        this.setState({ packageHeight: event.target.value });
    };
    handleSendFlowEnabledChange = event => {
        this.setState({ isSendFlowEnabled: event.target.checked });
    }
    handleWHIntegrationChange = (event) => {
        this.setState({ WHIntegration: event.target.value });
    };

    backBtnClick = (event) => {
        event.preventDefault();
        window.location.href = '/brandmarketmaps';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const brandMarketMapDetail = {
                country: this.state.country,
                countryGrp: this.state.countryGrpName,
                brandId: this.state.brandId,
                dummyWarehouseId: this.state.dummyWarehouseId,
                slf: this.state.slf,
                bestSellingPotentialEnabled: this.state.bestSellingPotentialEnabled,
                taxCalculationEnabled : this.state.taxCalculationEnabled,
                shippingFeeExcluded: this.state.shippingFeeExcluded,
                keepOrSendDefault: this.state.keepOrSendDefault,
                platformType:this.state.platformType,
                primaryWH:this.state.primaryWH,
                packageType:this.state.packageType,
                packageLength: isNaN(parseInt(this.state.packageLength)) == false? parseInt(this.state.packageLength):0, 
                packageWidth:isNaN(parseInt(this.state.packageWidth)) == false? parseInt(this.state.packageWidth):0,
                packageHeight:isNaN(parseInt(this.state.packageHeight) == false)? parseInt(this.state.packageHeight):0,
                isSendFlowEnabled:this.state.isSendFlowEnabled,
                Integrations: this.state.selectedIntegratoins,
                ActionBy: this.loginUserEmail,
                WHIntegration: this.state.WHIntegration
            };

            getHttpCommon(this.state.accessToken).post('api/brandmarketmap', brandMarketMapDetail)
                .then(res => {
                    alert(res.data);
                    //window.location.href = '/brandmarketmaps';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data;
                    if(errorMessage.status != undefined ){
                        var title = errorMessage.title 
                        var detail = "";
                        if(errorMessage.detail != undefined){
                            detail=errorMessage.detail;
                        }else if(errorMessage.errors != undefined){
                            detail= JSON.stringify(errorMessage.errors);
                        }
                        alert(title + "," + detail);
                    }
                    else
                    {
                        //alert(JSON.stringify(errorMessage));
                        alert("Error: " + "Unable to add Brand Market Mapping");
                    }
                });
        }
    }

    onSelect= (selectedList, selectedItem) => {
        selectedItem.IsSelected = 1;
       this.setState({ selectedIntegratoins: selectedList})
    }
    
    onRemove= (selectedList, removedItem) => {
        this.setState({ selectedIntegratoins: selectedList})
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <div className="row">
                <div style={{ marginTop: '20px', width: '100%'}}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Add New Brand Market Mapping</h4>

                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <table style={{ marginTop: '20px', width: '100%'}} className="formInnerStyle">
                                <tr >
                                    <td>
                                    <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                        <Form.Control as="select" placeholder="Store" required onChange={this.handleCountryChange} >
                                            <option value=''>--Select a Country--</option>
                                            {this.state.markets.map((item) => (
                                                <option value={item.country}>{item.country}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Country
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                    <Form.Label>Country Group (Area)</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Country Group Name"
                                        onChange={this.handleCCNameChange}
                                    />
                                     <Form.Control.Feedback type="invalid">
                                        Please provide Country Group Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Group>
                                        <Form.Label>Brand</Form.Label>
                                            <Form.Control as="select" placeholder="Store" required onChange={this.handleBrandIdChange} >
                                                <option value=''>--Select a Brand--</option>
                                                {this.state.brands.map((item) => (
                                                    <option value={item.brandId}>{item.name}</option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Brand
                                            </Form.Control.Feedback>
                                        </Form.Group>    
                                        <Form.Label>Keep Or Send Default</Form.Label>
                                        <Form.Group className="mb-3">
                                            <Form.Check type="radio" id="idRadioKeep" name="exampleRadios" label="Keep" value="Keep" defaultChecked onChange={this.handleKeepOrSendDefaultChange} />
                                            <Form.Check type="radio" id="idRadioSend" name="exampleRadios" label="Send" value="Send" onChange={this.handleKeepOrSendDefaultChange} />
                                        </Form.Group> 
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                        <Form.Check type="checkbox" label="SLF"
                                            onChange={this.handleSlfChange} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                            <Form.Check type="checkbox" label="Enable Best Selling Potential"
                                                onChange={this.handleBspEnabledChange} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                            <Form.Check type="checkbox" label="Tax Calculation"
                                                onChange={this.handleTaxCalEnabledChange} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                            <Form.Check type="checkbox" label="Exclude Shipping Fee"
                                                onChange={this.handleShippingFeeEnabledChange} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                            <Form.Check type="checkbox" label="New Send Flow"
                                                onChange={this.handleSendFlowEnabledChange} />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Group>
                                        <Form.Label>Platform Type</Form.Label>
                                            <Form.Control as="select" placeholder="Platform Type" required onChange={this.handleplatformTypeChange} >
                                                <option value=''>--Select a Platform Type--</option>
                                                <option value='Hybris'>Hybris</option>
                                                <option value='Centra'>Centra</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Country
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Primary WH</Form.Label>
                                            <Form.Control
                                                type="text"
                                                maxLength={5}
                                                placeholder="Primary WH"
                                                onChange={this.handleprimaryWHChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Primary WH
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Package Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                maxLength={3}
                                                placeholder="Package Type"
                                                onChange={this.handlepackageTypeChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Package Type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Package Length</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value = '0'
                                                placeholder="Package Length"
                                                onChange={this.handlepackageLengthChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Package Length
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Package Width</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value = '0'
                                                placeholder="Package Width"
                                                onChange={this.handlepackageWidthChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Package Width
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Package Height</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Package Height"
                                                value = '0'
                                                onChange={this.handlepackageHeightChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Package Height
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>WH Integration</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="WH Integration"
                                                onChange={this.handleWHIntegrationChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide WH Integration
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                </tr>
                            </table>
                        
                                <Form.Group>
                                    <Form.Label>Integrations</Form.Label>
                                    <Multiselect
                                        options={this.state.integrations}    // Options to display in the dropdown
                                        selectedValues={this.state.selectedIntegratoins} // Preselected value to persist in dropdown
                                        onSelect={this.onSelect}        // Function will trigger on select event
                                        onRemove={this.onRemove}        // Function will trigger on remove event
                                        displayValue="integrationName"             // Property name to display in the dropdown options
                                        avoidHighlightFirstOption={true}
                                        showCheckbox={true}
                                       />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                <div className='form-group' style={{ marginTop: '20px',marginBottom: '30px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Add Brand Market Mapping</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}