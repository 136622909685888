import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import httpCommon, {getHttpCommon}  from '../../httpCommon'
import UserContext from './../Context/SampleContext'
import {isWritePermission, validateRole} from '../../Constants/Utilities'
import { loginRequest } from "../../authConfig";

export default class ShowBrands extends Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)
        this.state = {
            brands: [],
            accessToken: "",
            myprops: props
        }
        console.log("MyProps:====*****>", props)

        if(context.accountObj[0]!=undefined){
            this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
          }
          this.mslInstance = context.msalObj;
        
        this.loginUserPermission = context.userPermission
        console.log("userPermissionShowbrands", this.loginUserPermission)
    }

    componentDidMount() {

        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadBrandsList(response.accessToken)
        });
    }

    loadBrandsList(accessToken){
        getHttpCommon(accessToken).get('api/brand')
        .then(res => {
            console.log(res);
            var value = res.headers['set-cookie']
            console.log(value)
            //console.log(res.headers.get("set-cookie"));

            const brands = res.data;
            this.setState({ brands });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    deleteRow(brand, e) {
        var proceed = window.confirm("Are you sure you want to proceed to delete brand '" + brand.name + "'");
        if (proceed) {
            getHttpCommon(this.state.accessToken).delete('api/Brand/' + brand.id)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/brands';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        } else {
            // alert("Don't Delete "+ id);
        }

    }

    render() {
        return (
            <div className="containerStyle">
                <div className="subHeaderContainer">
                <div className="subTitle">Brands ({this.state.brands.length})</div>
                    <div className="subButtonSec">
                    <span className="btnNormal"  variant="secondary" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN"])} 
                                            onClick={() => this.props.history.push('/createbrand')}>Add a Brand</span>
                    </div>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr className="tableHeader">
                            <th>ID</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.brands.map((item) => (
                            <tr key={item.brandId} className='tableRow'>
                                <td>{item.brandId}</td>
                                <td>{item.name}</td>
                                <td>
                                {/* <input type="image" name="submit" src={EditLogo} border="0" alt="Edit"  style={{height:'25px', width:'20px'}}  onClick={() => this.props.history.push('/updatebrand/' + item.brandId)} />
                                <input type="image" name="submit" src={DeleteLogo} border="0" alt="Delete"  style={{height:'25px', width:'20px', marginLeft: '10px' }}  onClick={(e) => this.deleteRow(item.brandId, e)} />
                                */}
                                <span className="btnNormal" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN"])} onClick={() => this.props.history.push('/updatebrand/' + item.id)}>Update</span>
                               <span className="btnNegative" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN"])}  variant="danger" onClick={(e) => this.deleteRow(item, e)} style={{ marginLeft: '10px' }}>Delete</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}
