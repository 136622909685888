import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./web.config"
import { AzureAD } from 'react-aad-msal';
import { Config } from './Config';


import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);


/*ReactDOM.render(
  <React.StrictMode>
    <AzureAD provider={Config} forceLogin={false}>
    {
      ({ login, logout, authenticationState, error, accountInfo }) => {
        
        if(accountInfo != undefined ){
           console.log(accountInfo.jwtIdToken)
          }
           return <App accountInfo={accountInfo} authState={authenticationState}
            loginFunc={login} logoutFunc={logout} error={error}/>
      }
    }
    </AzureAD>,
  </React.StrictMode>,
  document.getElementById('root')
);*/

ReactDOM.render(
      <MsalProvider instance={msalInstance}>
          <App />
      </MsalProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
