import React from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import httpCommon, {getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";
import Multiselect from 'multiselect-react-dropdown';

export default class UpdateBrandMarketMap extends React.Component {
    static contextType = UserContext;
    
    constructor(props,context) {
        super(props,context)

        this.state = {
            country: '',
            validated: false,
            slf: false,
            bestSellingPotentialEnabled: false,
            taxCalculationEnabled: false,
            shippingFeeExcluded: false,
            platformType:"",
            primaryWH:"",
            packageType:"",
            packageLength:0,
            packageWidth:0,
            packageHeight:0,
            isSendFlowEnabled:false,
            brands: [],
            markets: [],
            accessToken: '',
            integrations: [],
            selectedIntegratoins: [],
            WHIntegration: ''
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;
        
        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])

        this.loginUserEmail = context.userEmail
        this.loginUserName = context.userName
    }

    componentDidMount() {

        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadBrandsList(response.accessToken)
            this.loadMarketsList(response.accessToken)
            this.loadBrandmappingList(response.accessToken)
            this.loadIntegrations(response.accessToken)
        });
    }

    loadBrandsList(accessToken){
        getHttpCommon(accessToken).get('api/Brand')
        .then(res => {
            this.setState({
                brands: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    loadMarketsList(accessToken){
        getHttpCommon(accessToken).get('api/Market')
            .then(res => {
                this.setState({
                    markets: res.data
                });
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage);
            });
    }

    loadIntegrations(accessToken){
        getHttpCommon(accessToken).get('api/BrandMarketMap/GetMarketIntegrations')
        .then(res => {
            this.setState({
                integrations: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    loadBrandmappingList(accessToken){
        var path = window.location.pathname.split('/');
        var countryCode = path[2];
        var brandId = path[3];
        var countryGrp = path[4];
        getHttpCommon(accessToken).get('api/brandmarketmap/' + countryCode + '/' + brandId + '/' + countryGrp)
            .then(res => {
                const brandmarketmap = res.data;
                this.setState({
                    country: brandmarketmap.country,
                    countryGrpName: brandmarketmap.countryGrp,
                    brandId: brandmarketmap.brandId,
                    slf: brandmarketmap.slf,
                    bestSellingPotentialEnabled: brandmarketmap.bestSellingPotentialEnabled,
                    taxCalculationEnabled : brandmarketmap.taxCalculationEnabled,
                    shippingFeeExcluded: brandmarketmap.shippingFeeExcluded,
                    keepOrSendDefault: brandmarketmap.keepOrSendDefault,
                    platformType:brandmarketmap.platformType,
                    primaryWH:brandmarketmap.primaryWH,
                    packageType:brandmarketmap.packageType,
                    packageLength:brandmarketmap.packageLength,
                    packageWidth:brandmarketmap.packageWidth,
                    packageHeight:brandmarketmap.packageHeight,
                    isSendFlowEnabled:brandmarketmap.isSendFlowEnabled,
                    WHIntegration: brandmarketmap.whIntegration
                });

                if(brandmarketmap.integrations!= undefined && brandmarketmap.integrations!=null){
                    var filterResult = brandmarketmap.integrations.filter(d => d.isSelected == 1);

                    this.setState({selectedIntegratoins: filterResult})
                }

                if (brandmarketmap.keepOrSendDefault === 'Keep')
                    this.setState({ keepFlag: true, sendFlag: false });
                if (brandmarketmap.keepOrSendDefault === 'Send')
                    this.setState({ keepFlag: false, sendFlag: true });
            }).catch(function (error) {
                alert(error);
            });
    }

    handleCountryChange = event => {
        this.setState({ country: event.target.value });
    }

    handleCCNameChange = event => {
        this.setState({ countryGrpName: event.target.value });
    }

    handleBrandIdChange = event => {
        this.setState({ brandId: event.target.value });
    }

    handleSlfChange = event => {
        this.setState({ slf: event.target.checked });
    }

    handleBspEnabledChange = event => {
        this.setState({ bestSellingPotentialEnabled: event.target.checked });
    }

    handleTaxCalEnabledChange = event => {
        this.setState({ taxCalculationEnabled: event.target.checked });
    }

    handleShippingFeeEnabledChange = event => {
        this.setState({ shippingFeeExcluded: event.target.checked });
    }

    handleKeepOrSendDefaultChange = (event) => {
        if (event.target.value === 'Keep')
            this.setState({ keepFlag: true, sendFlag: false });
        if (event.target.value === 'Send')
            this.setState({ keepFlag: false, sendFlag: true });
        this.setState({ keepOrSendDefault: event.target.value });
    };

    handleplatformTypeChange = (event) => {
        this.setState({ platformType: event.target.value });
    };
    handleprimaryWHChange = (event) => {
        this.setState({ primaryWH: event.target.value });
    };
    handlepackageTypeChange = (event) => {
        this.setState({ packageType: event.target.value });
    };
    handlepackageLengthChange = (event) => {
        this.setState({ packageLength: event.target.value });
    };
    handlepackageWidthChange = (event) => {
        this.setState({ packageWidth: event.target.value });
    };
    handlepackageHeightChange = (event) => {
        this.setState({ packageHeight: event.target.value });
    };
    handleSendFlowEnabledChange = event => {
        this.setState({ isSendFlowEnabled: event.target.checked });
    }
    handleWHIntegrationChange = (event) => {
        this.setState({ WHIntegration: event.target.value });
    };

    backBtnClick = (event) => {
        event.preventDefault();
        window.location.href = '/brandmarketmaps';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const brandMarketMapDetail = {
                country: this.state.country,
                countryGrp: this.state.countryGrpName,
                brandId: this.state.brandId,
                slf: this.state.slf,
                bestSellingPotentialEnabled: this.state.bestSellingPotentialEnabled,
                taxCalculationEnabled: this.state.taxCalculationEnabled,
                shippingFeeExcluded: this.state.shippingFeeExcluded,    
                keepOrSendDefault: this.state.keepOrSendDefault,
                platformType:this.state.platformType,
                primaryWH:this.state.primaryWH,
                packageType:this.state.packageType,
                packageLength: isNaN(parseInt(this.state.packageLength)) == false? parseInt(this.state.packageLength):0, 
                packageWidth:isNaN(parseInt(this.state.packageWidth))== false? parseInt(this.state.packageWidth):0,
                packageHeight:isNaN(parseInt(this.state.packageHeight))== false? parseInt(this.state.packageHeight):0,
                isSendFlowEnabled:this.state.isSendFlowEnabled,
                Integrations: this.state.selectedIntegratoins,
                ActionBy: this.loginUserEmail,
                WHIntegration: this.state.WHIntegration
            };
            
            var path = window.location.pathname.split('/');
            var countryCode = path[2];
            var brandId = path[3];

            getHttpCommon(this.state.accessToken).put('api/brandmarketmap/' + countryCode + '/' + brandId + '/' + this.state.countryGrpName, brandMarketMapDetail)
                .then(res => {
                    alert(JSON.stringify(res.data));
                    //window.location.href = '/brandmarketmaps';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data;
                    if(errorMessage.status != undefined ){
                        var title = errorMessage.title 
                        var detail = "";
                        if(errorMessage.detail != undefined){
                            detail=errorMessage.detail;
                        }else if(errorMessage.errors != undefined){
                            detail= JSON.stringify(errorMessage.errors);
                        }
                        alert(title + "," + detail);
                    }
                    else
                    {
                        //alert(JSON.stringify(errorMessage));
                        alert("Error: " + "Unable to update Brand Market Mapping");
                    }
                });
        }
    }


    onSelect= (selectedList, selectedItem) => {
        selectedItem.IsSelected = 1;
       this.setState({ selectedIntegratoins: selectedList})
    }
    
    onRemove= (selectedList, removedItem) => {
        this.setState({ selectedIntegratoins: selectedList})
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <div className="row">
                    <div style={{ marginTop: '20px', width: '100%'}}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Update Brand Market Mapping</h4>
                           
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <table style={{ marginTop: '20px', width: '100%'}} className="formInnerStyle">
                                <tr >
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formLabel">Country</Form.Label>
                                        <Form.Control as="select" placeholder="Store"  disabled required value={this.state.country} onChange={this.handleCountryChange} >
                                            <option value=''>--Select a Country--</option>
                                            {this.state.markets.map((item) => (
                                                <option value={item.country}>{item.country}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select Country
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                    <Form.Label>Country Group (Area)</Form.Label>
                                        <Form.Control
                                            disabled
                                            required
                                            type="text"
                                            value={this.state.countryGrpName}
                                            placeholder="Country Group Name"
                                            onChange={this.handleCCNameChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Country Group Name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label>Brand</Form.Label>
                                            <Form.Control as="select" placeholder="Store" disabled required value={this.state.brandId} onChange={this.handleBrandIdChange} >
                                                <option value=''>--Select a Brand--</option>
                                                {this.state.brands.map((item) => (
                                                    <option value={item.brandId}>{item.name}</option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Brand
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Label>Keep Or Send Default</Form.Label>
                                        <Form.Group className="mb-3">
                                            <Form.Check type="radio" id="idRadioKeep" name="exampleRadios" label="Keep" value="Keep" checked={this.state.keepFlag} onChange={this.handleKeepOrSendDefaultChange} />
                                            <Form.Check type="radio" id="idRadioSend" name="exampleRadios" label="Send" value="Send" checked={this.state.sendFlag} onChange={this.handleKeepOrSendDefaultChange} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                        <Form.Check type="checkbox" label="SLF" checked={this.state.slf}
                                            onChange={this.handleSlfChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                        <Form.Check type="checkbox" label="Best Selling Potential" checked={this.state.bestSellingPotentialEnabled}
                                            onChange={this.handleBspEnabledChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                        <Form.Check type="checkbox" label="Tax Calculation" checked={this.state.taxCalculationEnabled} 
                                            onChange={this.handleTaxCalEnabledChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                        <Form.Check type="checkbox" label="Exclude Shipping Fee" checked={this.state.shippingFeeExcluded} 
                                            onChange={this.handleShippingFeeEnabledChange}/>
                                    </Form.Group>   
                                    <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                        <Form.Check type="checkbox" label="New Send Flow" checked={this.state.isSendFlowEnabled}
                                            onChange={this.handleSendFlowEnabledChange} />
                                    </Form.Group>             
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label>Platform Type</Form.Label>
                                        <Form.Control as="select" placeholder="Platform Type" required value={this.state.platformType} onChange={this.handleplatformTypeChange} >
                                            <option value=''>--Select a Platform Type--</option>
                                            <option value='Hybris'>Hybris</option>
                                            <option value='Centra'>Centra</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Country
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label>Primary WH</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength={5}
                                            value={this.state.primaryWH}
                                            placeholder="Primary WH"
                                            onChange={this.handleprimaryWHChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Primary WH
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label>Package Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength={3}
                                            value={this.state.packageType}
                                            placeholder="Package Type"
                                            onChange={this.handlepackageTypeChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Package Type
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label>Package Length</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={this.state.packageLength}
                                            placeholder="Package Length"
                                            onChange={this.handlepackageLengthChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Package Length
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Package Width</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={this.state.packageWidth}
                                                placeholder="Package Width"
                                                onChange={this.handlepackageWidthChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Package Width
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label>Package Height</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={this.state.packageHeight}
                                            placeholder="Package Height"
                                            onChange={this.handlepackageHeightChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Package Height
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                            <Form.Label>WH Integration</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="WH Integration"
                                                value={this.state.WHIntegration}
                                                onChange={this.handleWHIntegrationChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide WH Integration
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                </tr>
                            </table>

                                <Form.Group>
                                    <Form.Label>Integrations</Form.Label>
                                    <Multiselect
                                        options={this.state.integrations}    // Options to display in the dropdown
                                        selectedValues={this.state.selectedIntegratoins} // Preselected value to persist in dropdown
                                        onSelect={this.onSelect}        // Function will trigger on select event
                                        onRemove={this.onRemove}        // Function will trigger on remove event
                                        displayValue="integrationName"             // Property name to display in the dropdown options
                                        avoidHighlightFirstOption={true}
                                        showCheckbox={true}
                                       />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                <div className='form-group' style={{ marginTop: '20px',marginBottom: '30px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Update Brand Market Mapping</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}