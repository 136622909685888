import { read, utils, writeFileXLSX} from 'xlsx';
import * as XLSX from 'xlsx';

export const exportExcel = (json, fileName, sheetName) => {
  const ws = utils.json_to_sheet(json);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, sheetName);
  writeFileXLSX(wb, fileName + ".xlsx");
}

//Example
//[{data:[], sheetName:""}, {data:[], sheetName:""}]
export const exportExcelMoreSheets = (jsonArray, fileName) => {
  const wb = utils.book_new();
  jsonArray.map(sheetObj=>{
    const ws = utils.json_to_sheet(sheetObj.data);
    utils.book_append_sheet(wb, ws, sheetObj.sheetName);
  })
  writeFileXLSX(wb, fileName + ".xlsx");
}

export const exportCSV = (csvData, fileName, sheetName) => {
  const blob = new Blob([csvData], {type : 'application/csv'});
  const a = document.createElement('a');
  a.download = fileName + ".csv";
  a.href = URL.createObjectURL(blob);
  a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
}  

export const vaidateUserData = (fileObj, onUpdateStatus) => {
  try
  {
    if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            var validation1 = false
            var validation2 = false
            var validation3 = false

            const workbook = XLSX.read(reader.result, {type: 'binary'});
            const firstSheet = workbook.SheetNames[0]; //Sheet Name 
            const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
            const singleObj = excelRows[0]; //Fist object
            const sheetObj  = workbook.Sheets[firstSheet]
            
            validation1 = (firstSheet.toLocaleLowerCase() === "userdata"); //To Validate the Sheet Name
            validation2 = (excelRows.length>0); //to valdidate if the sheet has atleast one data
            validation2 = true
            
            /*validation3 =  (singleObj.hasOwnProperty("Country") && singleObj.hasOwnProperty("Brand") && singleObj.hasOwnProperty("Area")
            && singleObj.hasOwnProperty("Site Number") && singleObj.hasOwnProperty("Site") && singleObj.hasOwnProperty("Last Name") 
            && singleObj.hasOwnProperty("First Name") && singleObj.hasOwnProperty("Employee Name") && singleObj.hasOwnProperty("EmailID")
            && singleObj.hasOwnProperty("A Number") && singleObj.hasOwnProperty("Job Description"))*/

            //To validate the column Name
            validation3 =  (sheetObj.A1.h == "Country" && sheetObj.B1.h == "Brand" && sheetObj.C1.h == "Area" 
            && sheetObj.D1.h =="Site Number" && sheetObj.E1.h =="Site" && sheetObj.F1.h =="Last Name" 
            && sheetObj.G1.h == "First Name" && sheetObj.H1.h =="Employee Name" && sheetObj.I1.h =="EmailID" 
            && sheetObj.J1.h == "A Number" && sheetObj.K1.h == "Job Description")

            if(validation1 && validation2 && validation3)
            {
              onUpdateStatus("success")
            }
            else
            {
              onUpdateStatus("Something missing in the Excel, please check and try again.")
            }
          };
          reader.readAsBinaryString(fileObj); 
        }
    } else {
        onUpdateStatus("Something missing in the Excel, please check and try again.")
    }
  }
  catch(e){
    console.log(e);
  }
  return false;
}
