import React from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class UpdateRole extends React.Component {
    static contextType = UserContext;
    
    constructor(props,context) {
        super(props,context)
        this. state = {
            validated: false,
            accessToken: ''
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadRolesList(response.accessToken)
        });
    }

    loadRolesList(accessToken){
        var path = window.location.pathname.split('/');
        var country = path[2];
        getHttpCommon(accessToken).get('api/role/' + country)
            .then(res => {
                const role = res.data;
                this.setState({
                    roleId: role.roleId,
                    description: role.description,
                    authorizedToReturn: role.authorizedToReturn,
                    authorizedToOverride: role.authorizedToOverride
                });
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage);
            });
    }

    handleRoleIdChange = event => {
        this.setState({ roleId: event.target.value });
    }

    handleDescriptionChange = event => {
        this.setState({ description: event.target.value });
    }

    handleAuthorizedToReturnChange = event => {
        this.setState({ authorizedToReturn: event.target.checked });
    }

    handleAuthorizedToOverrideChange = event => {
        this.setState({ authorizedToOverride: event.target.checked });
    }

    backBtnClick = () => {
        window.location.href = '/roles';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
        event.preventDefault();

        const roleDetail = {
            roleId: this.state.roleId,
            description: this.state.description,
            authorizedToReturn: this.state.authorizedToReturn,
            authorizedToOverride: this.state.authorizedToOverride
        };
        var path = window.location.pathname.split('/');
        var roleId = path[2];

        getHttpCommon(this.state.accessToken).put('api/role/' + roleId, roleDetail)
            .then(res => {
                alert(res.data);
            //     window.location.href = '/roles';
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage);
            });
        }
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1" style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Update Role</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Role Id</Form.Label>
                                    <Form.Control
                                        required
                                        disabled
                                        type="text"
                                        placeholder="Role Id"
                                        value={this.state.roleId}
                                        onChange={this.handleRoleIdChange}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Role Id
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Description"
                                        value={this.state.description}
                                        onChange={this.handleDescriptionChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Description
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                    <Form.Check type="checkbox" label="Authorized To Return"
                                        checked={this.state.authorizedToReturn}
                                        onChange={this.handleAuthorizedToReturnChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                    <Form.Check type="checkbox" label="Authorized To Override"
                                        checked={this.state.authorizedToOverride}
                                        onChange={this.handleAuthorizedToOverrideChange} />
                                </Form.Group>
                                <div className='form-group' style={{ marginTop: '20px',marginBottom: '30px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Update Role</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}