import './App.css';
import { Route, Redirect, Switch, BrowserRouter as Router, BrowserRouter,Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import Home from './Pages/Home';
import ShowUsers from './Pages/User/ShowUsers';
import ShowStatistics from './Pages/Statistics/ShowStatistics';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import AddUser from './Pages/User/AddUser';
import UpdateUser from './Pages/User/UpdateUser'
import ShowBrands from "./Pages/Brand/ShowBrands"
import AddBrand from './Pages/Brand/AddBrand';
import UpdateBrand from './Pages/Brand/UpdateBrand';
import ShowStores from './Pages/Store/ShowStores';
import AddStore from './Pages/Store/AddStore';
import UpdateStore from './Pages/Store/UpdateStore';
import ShowDevices from './Pages/Device/ShowDevices'; // Devices
import ShowMarkets from './Pages/Market/ShowMarkets';
import AddMarket from './Pages/Market/AddMarket';
import UpdateMarket from './Pages/Market/UpdateMarket';
import ShowRoles from './Pages/Role/ShowRoles';
import AddRole from './Pages/Role/AddRole';
import UpdateRole from './Pages/Role/UpdateRole';
import ShowBrandMarketMaps from './Pages/BrandMarketMapping/ShowBrandMarketMaps';
import AddBrandMarketMap from './Pages/BrandMarketMapping/AddBrandMarketMap';
import UpdateBrandMarketMap from './Pages/BrandMarketMapping/UpdateBrandMarketMap';
import ShowCountryManagers from "./Pages/CountryManager/ShowCountryManagers"
import AddCountryManager from './Pages/CountryManager/AddCountryManager';
import UpdateCountryManager from './Pages/CountryManager/UpdateCountryManager';
import ShowORISTrans from './Pages/Retrigger/ShowORISTrans';
import PendingItems from './Pages/BoxHandling/PendingItems';
import Boxes from './Pages/BoxHandling/Boxes';
import HelpComponent from './Pages/HelpPage/HelpComponent'

import React, { useState, useEffect} from 'react'
import SampleContext from './Pages/Context/SampleContext';
import AppImage from './applogo.png';
import styled from 'styled-components';
import { validateRole } from './Constants/Utilities';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal }
  from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { getHttpCommon } from './httpCommon';
import PageNotFound from './components/PageNotFound';
import RouteNotFound from './components/RouteNotFound';
import { GlobalDebug } from "./components/GlobalDebug";

export const LoginSuccessContent = (props) => {
  let permission = ""
  let name = "!"
  let email = "!"
  let userRoles = []
  let accessToken = "";
  let selectedMenu =0
  let openDialog = true;
  sessionStorage.IsConfigErrorShown="false";

  const [open, setOpen] = React.useState(false);
  //const [isSendFlowEnabled, setSendFlowFlag] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    //window.location = "/users";
  };
  const handleOpen = () => {
      setOpen(true);
  };
  
  //Styled componet for Header section 
  const Styles = styled.div`
 '.navbar { 
   background-color: #F2F2F2 !important;
   }
 a, .navbar-nav, .navbar-light .nav-link {
   color: #001100;
   &:hover { color: maroon; }
 }
 .navbar-brand {
   font-size: 1.4em;
   color: #010;
   &:hover { color: maroon; }
 }
 .form-center {
   position: absolute !important;
   left: 25%;
   right: 25%;
 }
 `;

  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING" || process.env.REACT_APP_ENV === "PRODUCTION") &&
      GlobalDebug(false);
  }, []);

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch(e => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    }
  }

  const handleLogout = (logoutType) => {  
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  useEffect(() => {
    console.log("Homepage======>", props);
  });

  var path = window.location.href;
  if(path.includes("/brands")) selectedMenu = 1
  if(path.includes("/markets")) selectedMenu = 2
  if(path.includes("/roles")) selectedMenu = 3
  if(path.includes("/stores")) selectedMenu = 4
  if(path.includes("/devices")) selectedMenu = 5
  if(path.includes("/users")) selectedMenu = 6
  if(path.includes("/statistics")) selectedMenu = 7
  if(path.includes("/countrymanagers")) selectedMenu = 8
  if(path.includes("/brandmarketmaps")) selectedMenu = 9
  if(path.includes("/retrigger")) selectedMenu = 10
  if(path.includes("/handleitem")) selectedMenu = 11
  if(path.includes("/boxes")) selectedMenu = 12

  const [storeDet, setStoreValue] = useState(" ");
  const {instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [storeDataList, setUserStoreList] = useState( []);
  const [isSendFlowEnabled, setSendFlowFlag] = useState(false);

  if (accounts[0] != undefined) {
    //Read Name
    name = accounts[0].name;
    console.log(accounts);

    //Read email address
    email = accounts[0].username;

    //Read permissions
    permission = accounts[0].idTokenClaims?.roles;
    console.log("permission======>", permission);

    //Read Roles
    userRoles = accounts[0].idTokenClaims?.roles;
    useEffect(() => {
    console.log(userRoles);
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        let accessToken = response.accessToken
        let path = 'api/Store/storeByUserEmail/' + email
        
        // https://dev-we-webapp-adminportal-api.azurewebsites.net/api/Store/storeByUserId/A1219784
        console.log("AUTH" , "AUTH>>>>>>>>>>");
        getHttpCommon(accessToken).get(path)
          .then(res => {
            //setTimeout(() => {
              setUserStoreList(res.data)
              setStoreValue(res.data[0].storeName + " (" +res.data[0].storeId + ")" )

              try{
                  var isTrue = res.data.find(e=>e.isSendFlowEnabled == 1)
                  if(isTrue != undefined && isTrue.isSendFlowEnabled == 1){
                    setSendFlowFlag(true)
                  }
                  else
                  {
                    setSendFlowFlag(false)
                  }
              }
              catch(error){

              }

            console.log("RES_!", res.data[0].storeName)
            sessionStorage.IsConfigErrorShown = "false"
          }).catch(function (error) {
            console.log("Error Message1: ", error)
            var response = error.response;
            var errorMessage = response.data.detail;
            handleOpen();
          });
      });
    }, []);
  }

  const routes = [
    { path: ["/"], component: Home, exact: true, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},
    { path: ["/home"], component: Home, elementRoles:["HM_SADMIN", "HM_ADMIN", "HM_EXTERNAL"]},

    //Users
    { path: ["/users"], component: ShowUsers, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},
    { path: ["/createuser"], component: AddUser, elementRoles:["HM_SADMIN","HM_PADMIN", "HM_ADMIN", "HM_EXTERNAL"]},
    { path: ["/updateuser/:userId/:storeId"], component: UpdateUser,elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},

    //Stores
    { path: ["/stores"], component: ShowStores, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},
    { path: ["/createstore"], component: AddStore, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},
    { path: ["/updatestore/:storeId"], component: UpdateStore, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},
    { path: ["/handleitem"], component: PendingItems, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},
    { path: ["/boxes"], component: Boxes, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},

    //Devices
    { path: ["/devices"], component: ShowDevices, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]},

    //Retrigger option
    { path: ["/retrigger"], component: ShowORISTrans, elementRoles:["HM_SADMIN", "HM_PADMIN"]},


    //Statistics
    { path: ["/statistics"], component: ShowStatistics, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN"]},

    //Brands
    { path: ["/brands"], component: ShowBrands, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/createbrand"], component: AddBrand, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/updatebrand/:brandId"], component: UpdateBrand, elementRoles:["HM_SADMIN","HM_PADMIN"]},

    //Markets
    { path: ["/markets"], component: ShowMarkets, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/createmarket"], component: AddMarket, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/updatemarket/:marketId"], component: UpdateMarket, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    
    //Roles
    { path: ["/roles"], component: ShowRoles, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/createrole"], component: AddRole, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/updaterole/:roleId"], component: UpdateRole, elementRoles:["HM_SADMIN","HM_PADMIN"]},

    //Brand Market Mapping
    { path: ["/brandmarketmaps"], component: ShowBrandMarketMaps, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/createbrandmarketmap"], component: AddBrandMarketMap, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/updatebrandmarketmap/:country/:brandId"], component: UpdateBrandMarketMap, elementRoles:["HM_SADMIN","HM_PADMIN"]},

    //Country Manager
    { path: ["/countrymanagers"], component: ShowCountryManagers, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/createcountrymanager"], component: AddCountryManager, elementRoles:["HM_SADMIN","HM_PADMIN"]},
    { path: ["/updatecountrymanager/:id"], component: UpdateCountryManager, elementRoles:["HM_SADMIN","HM_PADMIN"]},

    { path: ["/*"], component: RouteNotFound, exact: true,elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN"]},

     //Help
     { path: ["/help"], component: HelpComponent, elementRoles:["HM_SADMIN", "HM_ADMIN","HM_PADMIN"]},
  ];

  const ACCESS_TOKEN = "token";

  const readFromStorage = (token) => token;
  const isFalsy = (val) => !Boolean(val);

  const PrivateRoute = (props) => {
    const accessToken = readFromStorage(ACCESS_TOKEN);
  
    return !isFalsy(accessToken) ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
    );
  };

  return (
    <Router>
      <Styles>
        <Navbar bg="light" expand="lg" className="mainHeader">
          <div className="customHeaderContainer">
              <div className="headerColumns">
                <div className="sidebar-first">
                  <div className="leftSideRows">
                    <div class="logoRow1">
                      <Navbar.Brand href="/home"><h3><img src={AppImage} alt="horse" className="logoImage" /><b className="headerName">ORIS 3.1 Admin portal</b></h3></Navbar.Brand>
                    </div>
                    <div class="logoRow2">
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav cusomNav" >
                        <Nav>
                          {validateRole(userRoles, ["HM_SADMIN","HM_PADMIN"]) && <Nav.Link href='/brands'><b class={`${selectedMenu==1 ? "navMenuSel" : "navMenuNormal"}`}>Brands</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN","HM_PADMIN"]) && <Nav.Link href='/markets'><b class={`${selectedMenu==2 ? "navMenuSel" : "navMenuNormal"}`}>Markets</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN","HM_PADMIN"]) && <Nav.Link href='/roles'><b className={`${selectedMenu==3 ? "navMenuSel" : "navMenuNormal"}`}>Roles</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]) && <Nav.Link href='/stores'><b className={`${selectedMenu==4 ? "navMenuSel" : "navMenuNormal"}`}>Stores</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]) && <Nav.Link href='/devices'><b className={`${selectedMenu==5 ? "navMenuSel" : "navMenuNormal"}`}>Devices</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]) && <Nav.Link href='/users'><b className={`${selectedMenu==6 ? "navMenuSel" : "navMenuNormal"}`}>Users</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN"]) && <Nav.Link href='/statistics'><b className={`${selectedMenu==7 ? "navMenuSel" : "navMenuNormal"}`}>ORIS Statistics</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN","HM_PADMIN"]) && <Nav.Link href='/countrymanagers'><b className={`${selectedMenu==8 ? "navMenuSel" : "navMenuNormal"}`}>CM</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN","HM_PADMIN"]) && <Nav.Link href='/brandmarketmaps'><b className={`${selectedMenu==9 ? "navMenuSel" : "navMenuNormal"}`}>BM Mappings</b></Nav.Link>}
                          {validateRole(userRoles, ["HM_SADMIN","HM_PADMIN"]) && <Nav.Link href='/retrigger'><b className={`${selectedMenu==10 ? "navMenuSel" : "navMenuNormal"}`}>ORIS Tracker</b></Nav.Link>}
                          {(validateRole(userRoles, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]) && isSendFlowEnabled) && <Nav.Link href='/handleitem'><b className={`${selectedMenu==11 ? "navMenuSel" : "navMenuNormal"}`}>Pending Items</b></Nav.Link>}
                          {(validateRole(userRoles, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"]) && isSendFlowEnabled) && <Nav.Link href='/boxes'><b className={`${selectedMenu==12 ? "navMenuSel" : "navMenuNormal"}`}>Boxes</b></Nav.Link>}
                        </Nav>
                      </Navbar.Collapse>
                    </div>
                  </div>
                </div>
                <div className="sidebar-second">
                  <ul>
                    <li><b>Welcome</b> {name}</li>
                    <li>{storeDet}</li>
                    <li className="logoutText">
                      <button onClick={() => handleLogout("redirect")} className="logingButton">Logout</button>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </Navbar>
        <Modal show={open} className='ModalStyle'>
          <Modal.Header className="modelHeaderBG" >
            <Modal.Title>Configuration Error</Modal.Title>
            <span className="closeIconStyle2 size16" onClick={() => handleClose()}>&times;</span>
          </Modal.Header>
          <Modal.Body>
            <div className= "errHeader">
              Sorry for inconvenience, please check below instrutions to solve the issue
            </div>
            <div className= "errContent">
              <ul>
                <li>1. Please update your email address as per HM account</li>

                <li>2. (or) Pleae contact any other user who had this portal access</li>
                <li>3. (or) Please get support from Global IT team via incident</li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </Styles>
        <SampleContext.Provider value={{ userPermission: permission, userName: name, userEmail: email, userRoles: userRoles, accountObj: 
          accounts, msalObj: instance, userStoreDet:storeDet, userStoreList:storeDataList}}>
        <Switch>{routes.map(({elementRoles:eleRoles, ...props}) => {
          const RouteWrapper = validateRole(userRoles, eleRoles) ? Route: PageNotFound;
          return <RouteWrapper key={props.path[0]} {...props} />;
        })}
        </Switch>
        </SampleContext.Provider>
    </Router>
  );
}
export const LoginFailureContent = (props) => {
  const { instance, accounts } = useMsal();
  let permission = ""

  if (accounts[0] != undefined) {
    //Read Name
    name = accounts[0].name;
    console.log(accounts);

    //Read email address
    email = accounts[0].username;

    //Read permissions
    permission = accounts[0].idTokenClaims?.roles;
    console.log("permission======>", permission);
  }

  const handleLogin = () => {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
    });
  }

  return (
    <Router>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/home"><h3><img src={AppImage} alt="horse" className="logoImage" /><b>ORIS 3.1 Admin portal</b></h3></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            </Navbar.Collapse>
            <div class="user-info-container">
              <ul>
                <li>Welcome {name}</li>
                <li class="logoutText">
                  <button onClick={() => handleLogin("redirect")}>LOGIN</button>
                </li>
              </ul>
            </div>
          </Navbar>
          <Switch>
            <SampleContext.Provider value={{ user_permission: permission, userName: name }}>
              <Route path="/" component={Home} />
            </SampleContext.Provider>
          </Switch>
        </Router>
    )
}

export default function App(){
  return (
    <div>
      <AuthenticatedTemplate>
        <LoginSuccessContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginFailureContent />
      </UnauthenticatedTemplate>
    </div>
  )
}