import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config={
    auth: {

        clientId : "0f85602c-5d62-40ac-a439-404d23dc7b9e",
        Authority : "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
        //redirectUri : "http://localhost:3000/.auth/login",
        redirectUri : "https://oris3adminportal.azurewebsites.net",
        //redirectUri : "https://oris3adminportal.azurewebsites.net/.auth/login"
    },
    cache: {
        cacheLocation : "localStorage",
        storeAuthStateInCookie : true
    },
    system: {
        navigateFrameWait: 0,
    },
}

export const authenticationParameters = {
    scopes :[
        '0f85602c-5d62-40ac-a439-404d23dc7b9e/.default'
    ]
}

export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
}
export const Config = new MsalAuthProvider(config, authenticationParameters, options)