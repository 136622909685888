import * as CryptoJS from 'crypto-js';

export const decryptAES = (inputStr, key) => {
  const decrypted = CryptoJS.AES.decrypt(inputStr, key);
    if (decrypted) {
      try {
        console.log(decrypted);
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'error 1';
        } 
      } catch (e) {
        return 'error 2';
      }
    }
    return 'error 3';
}

export const encryptAES = (inputStr, key) => {
  return CryptoJS.AES.encrypt(inputStr, key).toString();
}