import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getHttpCommon } from '../../httpCommon';
import {validateRole } from '../../Constants/Utilities';
import SampleContext from '../Context/SampleContext';

import { loginRequest } from "../../authConfig";
import LoadingSpinner from '../../LoadingSpinner';
import Pagination from '../../components/Pagination';
import {sendPushNotification} from '../../components/FCMHelper';

import { format } from "date-fns";

export default class ShowStatistics extends Component {
  static contextType = SampleContext;

  constructor(props, context) {
    super(props, context)

    var { storeDet } = context;

    this.state = {
      orisReport: [],
      accessToken: "",
      isLoading: false,
      filterInput: "",
      orisReportBase: [],
      currentPage: 1,
      selectedDate: new Date(),
      //selectedDate2: format((new Date()), "yyyy-MM-dd HH:mm")
      selectedDate2: format((new Date()), "yyyy-MM-dd")
    }

    console.log(JSON.stringify(context));

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail
    this.inputDisable = true
    this.pageSize = 15
    this.userStoreDetail = context.userStoreDet
    this.userStoreList = context.userStoreList;

    if(this.userStoreList != null && this.userStoreList.length>0){
      this.setState({selectedStore: this.userStoreList[0].storeId})
   }
  }

  componentDidMount() {
    //this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      this.setState({ accessToken: response.accessToken })
      //this.loadStoreList(response.accessToken)
      //this.loadStoreDeviceList("")
      this.setState({isLoading:false})
      //this.loadReport(response.accessToken,  "SE0802") //this.loginUserEmail)

      //alert("User Email=>" + this.userStoreList)

      this.loadReport(response.accessToken,  this.loginUserEmail, this.state.selectedDate)
    });
  }

  loadReport(accessToken, loginUserId, selDate, storeId=""){
    this.setState({ isLoading: true })
    //alert("USer Email=>" + loginUserId)
    var todayDateStr = selDate.toISOString().split('T')[0];

    var startTimeStr = todayDateStr + "T00:00:00";  //"2022-09-01T08:17:21";
    var endTimeStr = todayDateStr + "T23:59:59"; //"2022-09-20T08:17:21";

    //TODO: testing
    //startTime=2022-04-27T00:00:00&endTime=2022-04-27T23:59:59
    //startTimeStr = "2022-04-27T00:00:00";
    //endTimeStr = "2022-04-27T23:59:59";

    //TODO: ***** Validate the ROLE and build the Route *****
    var offset = (new Date()).getTimezoneOffset()
    var routePath = 'api/Report/storeId/' + loginUserId + "?startTime="+ startTimeStr +"&endTime=" + endTimeStr +"&timeZoneOffset=" + offset + "&storeId=" + storeId

    getHttpCommon(accessToken).get(routePath)
      .then(res => {

        const reportData = res.data;
        this.setState({ orisReport: reportData });
        this.setState({ orisReportBase: reportData })
        this.setState({ isLoading: false })

        /*if(reportData!=null){
          const size = reportData.filter(reportItem => reportItem.orisReturns>0).length
          if(size<1){
            alert("No Report found on this date")
          }
        }*/
        

      }).catch(function (error) {
        this.setState({isLoading:false})
        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage + ", or Please enter a valid store ID");
      });
  }

  //To load Store Details 
  loadStatisticsByStoreId(storeId) {
    //alert(this.userStoreList)
    alert("JOY==>" + this.userStoreDetail);

    this.loadReport(this.state.accessToken,  this.loginUserEmail, this.state.selectedDate, this.state.selectedStore)
    return;
    
    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/StoreDeviceInfo/GetStoreByStoreId/' + storeId

    getHttpCommon(this.state.accessToken).get(routePath)
      .then(res => {
        const devices = res.data;
        this.setState({ orisReport: devices });
        this.setState({ orisReportBase: devices })
        this.setState({ isLoading: false })
      }).catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage + ", or Please enter a valid store ID");
      });
  }

  handleError(){
    this.setState({ orisReport: []});
    this.setState({ orisReportBase: []})
  }

  handleStoreChange = (event) => {
    this.setState({ selectedStore: event.target.value });

    if (event.target.value == "filter") {
      this.inputDisable = true
    }
    else {
      this.inputDisable = false
    }

    this.loadReport(this.state.accessToken,  this.loginUserEmail, this.state.selectedDate, event.target.value)
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value })
  }

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;
    return this.state.orisReport.slice(firstPageIndex, lastPageIndex);
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  handleDateChange = (event) => {
    this.setState({ selectedDate: new Date(event.target.value) });
    this.setState({ selectedDate2: event.target.value });
    this.loadReport(this.state.accessToken, this.loginUserEmail, new Date(event.target.value));
};

  render() {
    const { orisReport, currentPage } = this.state;

    return (
      <div className="containerStyle">
        <div className="subHeaderContainer">
          <div className="subTitle">ORIS Daily Statistics</div>
          <div className="subButtonSec">
              <table border="0" >
                <tr>
                  <td style={{verticalAlign: "bottom"}}>
                    <Form.Group>
                        <Form.Label className="formFieldSpace"><b>Report Date</b></Form.Label>
                        <Form.Control type="date" placeholder="Report date" required onChange={this.handleDateChange} 
                          value={this.state.selectedDate2}/>
                        <Form.Control.Feedback type="invalid">
                            Please provide Role Valid From
                        </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                  <td style={{verticalAlign: "bottom"}}>
                  <SampleContext.Consumer>
                  {storeDet =>{
                    if(storeDet.userStoreList != null)
                    {
                          return <Form.Group>
                          <Form.Label><b>Store</b></Form.Label>
                          <Form.Control as="select" placeholder="Store" required 
                          value={this.state.selectedStore} 
                          onChange={this.handleStoreChange} 
                           style={{ backgroundColor: '#fff' }} >
                              <option value=''>--Select a Store--</option>
                              {storeDet.userStoreList.map((store, index) => (
                                  <option key={index} value={store.storeId}>{store.storeId}</option>
                              ))}
                          </Form.Control>
                      </Form.Group>
                      }
                    }}
                   </SampleContext.Consumer>
                  </td>
                  <td>
                    <Button onClick={() => this.loadStatisticsByStoreId(this.state.selectedStore)} style={{ margin: '10px', display:'none' }}>Get Report</Button>
                  </td>
                </tr>
              </table>
            
          </div>
        </div>

        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :
          <Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>Time/Hour</th>
                <th>ORIS transactions</th>
                <th>Keep Items - (total)</th>
                <th>Send Items - (total)</th>
                <th>Total Returned Items</th>
                <th>Total Amount</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              {this.currentTableData().map((item, index) => (
                //item.orisReturns != "0"
                //? 
                (<tr key={index} className="tableRow">
                  <td>{item.orisTimeDuration}</td>
                  <td>{item.orisReturns}</td>
                  <td>{item.keepItems} - ({Number(item.keepAmount).toFixed(2)}) </td>
                  <td>{item.sendItems} - ({Number(item.sendAmount).toFixed(2)})</td>
                  <td>{item.returnItems}</td>
                  <td>{Number(item.totalReturnAmount).toFixed(2)}</td>
                  <td>{item.currency}</td>
                </tr>)
                //: null
              ))}
              </tbody> 
          </Table>}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={orisReport.length}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
      </div>
    )
  }
}