import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import httpCommon, {getHttpCommon} from '../../httpCommon'
import { loginRequest } from "../../authConfig";
import UserContext from './../Context/SampleContext';
import {getLanguage} from '../../Constants/Utilities';

export default class AddStore extends React.Component {
    static contextType = UserContext;
    constructor(props,context) {
        super(props,context)

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
        this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;
        this.prefered_language = ["EN","RO","MX"]
        this.state = {
            validated: false,
            orisEnabled: false,
            brands: [],
            markets: [],
            marketGroup : [],
            preferedLanguage:"EN",
            keepOrSendDefault: "Keep"
        }
    }

    componentDidMount() {

        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.getBrandsList(response.accessToken)
            this.getMarketList(response.accessToken)
        });
    }

    getBrandsList(accessToken){
        getHttpCommon(accessToken).get('api/Brand')
        .then(res => {
            this.setState({
                brands: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    getCountryGroup(accessToken, countryCode, brandId){

        this.setState({
            marketGroup : []
        });
        //Validation
        if(countryCode!=undefined && brandId != undefined){

            getHttpCommon(accessToken).get('api/Market/Groups/' + countryCode + '/' + brandId)
            .then(res => {
                this.setState({
                    marketGroup : res.data
                });
            }).catch(function (error) {
                var response = error.response;
                var errorMessage = response.data.detail;
                alert(errorMessage + ", Please check brand market mapping for Country group configuration");                
            });
        }
    }

    getMarketList(accessToken){
        getHttpCommon(accessToken).get('api/Market')
        .then(res => {
            this.setState({
                markets: res.data
            });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }


    handleStoreIdChange = event => {
        this.setState({ storeId: event.target.value });
    }

    handleStoreNameChange = event => {
        this.setState({ storeName: event.target.value });
    }

    handleCGNameChange = event => {
        this.setState({ countryGrpName: event.target.value });
    }

    handleCountryChange = event => {
        this.setState({ country: event.target.value });
         //To Load group from API
         this.getCountryGroup(this.state.accessToken, event.target.value, this.state.brandId)
    }

    handleBrandIdChange = event => {
        this.setState({ brandId: event.target.value });
         //To Load group from API
         this.getCountryGroup(this.state.accessToken, this.state.country, event.target.value)
    }

    handleLanguageChange = event => {
     this.setState({ preferedLanguage: event.target.value });
      console.log("preferedLanguage",event.target.value)
    }

    handleOrisEnabledChange = event => {
        this.setState({ orisEnabled: event.target.checked });
    }

    handleKeepOrSendDefaultChange = (event) => {
        this.setState({ keepOrSendDefault: event.target.value });
    };

    backBtnClick = (event) => {
        event.preventDefault();
        //window.location.href = '/stores';
        this.props.callBackClose();
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const storeDetail = {
                storeId: this.state.storeId,
                storeName: this.state.storeName,
                country: this.state.country,
                brandId: this.state.brandId,
                orisEnabled: this.state.orisEnabled,
                keepOrSendDefault: this.state.keepOrSendDefault,
                PreferedLanguage:this.state.preferedLanguage,
                countryGrp:this.state.countryGrpName
            };

            getHttpCommon(this.state.accessToken, this.loginUserEmail).post('api/store', storeDetail)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/stores';
                    this.props.callBackClose();
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            <div id="container" >
                <div >
                    <div style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Add New Store</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label className='test'>Store Id</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Store Id"
                                        onChange={this.handleStoreIdChange}
                                    />
                                     <Form.Control.Feedback type="invalid">
                                        Please provide Store Id
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Store Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Store Name"
                                        onChange={this.handleStoreNameChange}
                                    />
                                     <Form.Control.Feedback type="invalid">
                                        Please provide Store Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required onChange={this.handleCountryChange} >
                                        <option value=''>--Select a Country--</option>
                                        {this.state.markets.map((item) => (
                                            <option className="notranslate" value={item.country}>{item.country}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required onChange={this.handleBrandIdChange} >
                                        <option value=''>--Select a Brand--</option>
                                        {this.state.brands.map((item) => (
                                            <option className="notranslate" value={item.brandId}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Brand
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Country Group</Form.Label>
                                    <Form.Control as="select" placeholder="Store" required onChange={this.handleCGNameChange} >
                                        <option value=''>--Select a Country Group--</option>
                                        {this.state.marketGroup.map((item) => (
                                            <option className="notranslate" value={item.countryGrp}>{item.countryGrp}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                
                                <Form.Label style={{ marginTop: '10px'}}>Keep Or Send Default</Form.Label>
                                <Form.Group  className="mb-3">
                                    <Form.Check type="radio" id="idRadioKeep" name="exampleRadios" label="Keep" value="Keep" defaultChecked onChange={this.handleKeepOrSendDefaultChange} />
                                    <Form.Check type="radio" id="idRadioSend" name="exampleRadios" label="Send" value="Send" onChange={this.handleKeepOrSendDefaultChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ marginTop: '20px',display:'visible' }}>
                                    <Form.Check type="checkbox" label="Oris Enabled" checked ={this.state.orisEnabled}
                                        onChange={this.handleOrisEnabledChange} />
                                </Form.Group>
                                <div style={{ marginTop: '30px',marginBottom: '20px', textAlign:"center"}}>
                                    <button className="btnNormalBkp" type="submit" style={{ marginTop: '10px' }}>Add Store</button>
                                    <span className="btnNormal" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}