import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getHttpCommon } from '../../httpCommon';
import {validateRole } from '../../Constants/Utilities';
import UserContext from '../Context/SampleContext';

import { loginRequest } from "../../authConfig";
import LoadingSpinner from '../../LoadingSpinner';
import Pagination from '../../components/Pagination';
import {sendPushNotification} from '../../components/FCMHelper';

export default class ShowDevices extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context)

    this.state = {
      storeDevices: [],
      accessToken: "",
      isLoading: false,
      filterInput: "",
      storeDeviceBase: [],
      currentPage: 1,

    }

    this.loginUserRoles = []
    if (context.accountObj[0] != undefined) {
      this.loginUserRoles = context.accountObj[0].idTokenClaims?.roles;
    }
    this.mslInstance = context.msalObj;
    this.loginUserPermission = context.userPermission
    this.loginUserEmail = context.userEmail,
      this.inputDisable = true
    this.pageSize = 7


  }


  componentDidMount() {
    this.setState({ isLoading: true })
    this.mslInstance.acquireTokenSilent({
      ...loginRequest,
      account: this.loginUserRoles
    }).then((response) => {
      console.log(response.accessToken);
      this.setState({ accessToken: response.accessToken })
      //this.loadStoreList(response.accessToken)
      //this.loadStoreDeviceList("")
      this.setState({isLoading:false})
    });
  }

  //To load Store Details 
  loadStoreDeviceList(storeId) {

    //TODO: ***** Validate the ROLE and build the Route *****
    var routePath = 'api/StoreDeviceInfo/GetStoreByStoreId/' + storeId

    getHttpCommon(this.state.accessToken).get(routePath)
      .then(res => {
        const devices = res.data;
        this.setState({ storeDevices: devices });
        this.setState({ storeDeviceBase: devices })
        this.setState({ isLoading: false })
      }).catch(function (error) {
        var response = error.response;
        var errorMessage = response.data.detail;
        alert(errorMessage + ", or Please enter a valid store ID");
      });
  }

  handleError(){
    alert("test")
    this.setState({ storeDevices: []});
    this.setState({ storeDeviceBase: []})
  }

  //To Delete selected store in the list
  deleteRow(storeId, deviceId , e) {
    var proceed = window.confirm("Are you sure you want to proceed to delete Device info " + deviceid);
    if (proceed) {
      getHttpCommon(this.state.accessToken).delete('api/StoreDeviceInfo/DeleteStoreDevice/' + storeId + "/" + deviceId)
        .then(res => {
          alert(res.data);
          window.location.reload(false);
        }).catch(function (error) {
          var response = error.response;
          var errorMessage = response.data.detail;
          alert(errorMessage);
        });
    } else {
    }
  }

  sendMessage(deviceInfo, e) {
    var messageToSend = window.prompt("Type in the Message to send!");
    if (messageToSend) {
      //sendPushNotification(deviceInfo.fcmAccessToken,"Message from admin", messageToSend, true)

      getHttpCommon(this.state.accessToken).put('api/StoreDeviceInfo/SendNotification?messageTitle=' + 'Message from admin' + '&message=' + messageToSend, deviceInfo)
        .then(res => {
          if(res.status != undefined){
            if(res.status == 200){
              alert(res.data);
            }
          }
          else{
            alert(res.data.detail);
          }
        }).catch((error) => {
          alert(error.response.data.detail)
        });

    } else {
    }
  }

  sendMessageToAll(e) {
    var messageToSend = window.prompt("Type in the Message to send to all the devices in this store!");
    if (messageToSend) {
      var devices = this.state.storeDevices
      devices.map(deviceInfo => {
        //sendPushNotification(deviceInfo.fcmAccessToken,"Message from admin", messageToSend, false)

        getHttpCommon(this.state.accessToken).put('api/StoreDeviceInfo/SendNotification?messageTitle=' + 'Message from admin' + '&message=' + messageToSend, deviceInfo)
        .then(res => {
          //console.log(res.data)
          //alert(res.data.detail);
        }).catch((error) => {
          //alert(error.response.data.detail)
        });
      })
    } else {
    }
  }

  unlockTheDevice(deviceInfo, storeId, deviceId , e) {
    var proceed = window.confirm("Are you sure you want to proceed to unlock the Device? " + storeId);
    //TODO: Get the selected device info by ID from the collection
    deviceInfo.isLocked = false
    if (proceed) {
      getHttpCommon(this.state.accessToken).put('api/StoreDeviceInfo/UpdateStoreDeviceInfo/', deviceInfo)
        .then(res => {
          alert(res.data);
          this.loadStoreDeviceList(deviceInfo.storeId)
        }).catch((response) => {
          var response = error.response;
          var errorMessage = response.data.detail;
          alert(errorMessage);
        });
    } else {
    }
  }

  handleFilterChange = (event) => {

    this.setState({ filterValue: event.target.value });
    this.setState({ filterInput: "" });

    if (event.target.value == "filter") {
      this.inputDisable = true
      this.setState({ stores: this.state.storeDeviceBase })
    }
    else {
      this.inputDisable = false
      this.setState({ stores: this.state.storeDeviceBase })
    }
  }

  handleFilterText = (event) => {
    this.setState({ filterInput: event.target.value })
  }

  currentTableData = () => {
    let firstPageIndex = (this.state.currentPage - 1) * this.pageSize;
    let lastPageIndex = firstPageIndex + this.pageSize;
    return this.state.storeDevices.slice(firstPageIndex, lastPageIndex);
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  render() {
    const { storeDevices, currentPage } = this.state;

    return (
      <div className="containerStyle">
        <div className="searchBox">
            <div className="searchBoxLabel">Search device</div>
            <div class="searchBoxContainer">
              <input className="storeIdBox" onChange={this.handleFilterText} disabled={false} value={this.state.filterInput} placeholder="Enter Store ID"></input>
              <span className="searchButton" onClick={() => this.loadStoreDeviceList(this.state.filterInput)}>Search</span>
            </div>
        </div>
        <div className="subHeaderContainer">
          <div className="subTitle subTitleMore">Store Devices ({this.state.storeDevices.length})</div>
          < div className="subButtonSec">
               <select style={{ margin: '10px', padding: '2px', display: 'none' }}
              value={this.state.filterValue}
              onChange={this.handleFilterChange}>
              <option value="filter">Filter By</option>
              <option value="storeId">Store Id</option>
              <option value="country">Country</option>
              <option value="brandName">Brand Name</option>
            </select>
           
            <Button disabled={!this.state.storeDevices.length >0} onClick={(e) => this.sendMessageToAll(e)} style={{ margin: '10px', display:'none' }}>Send Message to All</Button>
          </div>
        </div>

        {this.state.isLoading ? LoadingSpinner(this.state.isLoading) :
          <Table striped bordered hover>
            <thead>
              <tr className="tableHeader">
                <th>Store ID</th>
                <th>Device serial no.</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            {this.state.storeDevices.length > 0 ?

            <tbody>
              
              {this.currentTableData().map((item, index) => (
                <tr key={index} className='tableRow'>
                  <td>{item.storeId}</td>
                  <td>{item.deviceSerialNo}</td>
                  <td>{item.isLocked==true? <span className="textRed">Blocked</span>: <span className="textGreen">Active</span>}</td>
                  <td>
                  {item.isLocked==true?
                    <span className="unblockBtn" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} onClick={(e) => this.unlockTheDevice(item, item.storeId, item.deviceSerialNo, e)}>Unblock Device</span>:
                    <span className="unblockBtnDisabled" disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN", "HM_ADMIN","HM_PADMIN", "HM_EXTERNAL"])} >Unblock Device</span>
                  }

                    <Button disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])} variant="danger" onClick={(e) => this.deleteRow(item.storeId, item.deviceSerialNo, e)} style={{ marginLeft: '10px', display: 'none' }}>Delete</Button>
                    <Button disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN", "HM_EXTERNAL"])} variant="secondary" onClick={(e) => this.sendMessage(item, e)} style={{ marginLeft: '10px', display: 'none' }}>Send Message</Button>
                  </td>
                </tr>
              ))}
              </tbody> : <tr>
                  <td colSpan={6}><p className="errorMessage">Not Records Yet. Please enter store id and search</p></td>
                  </tr>}
          </Table>}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={storeDevices.length}
          pageSize={this.pageSize}
          onPageChange={page => this.setCurrentPage(page)}
        />
      </div>
    )
  }
}