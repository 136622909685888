import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission,validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class AddMarket extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)
        this.state = {
            brandId: '',
            validated: false,
            accessToken: ''
        }

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
        this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
        this.disable = false
        this.disable = validateRole(this.loginUserPermission, ["HM_SADMIN"])
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
          }).then((response) => {
            console.log(response.accessToken);
              //callMsGraph(response.accessToken).then(response => setGraphData(response));
              this.setState({ accessToken: response.accessToken })
          });
    }
   

    handleCountryChange = event => {
        this.setState({ country: event.target.value });
    }

    handleManagerOverrideRequiredChange = event => {
        this.setState({ managerOverrideRequired: event.target.checked });
    }

    backBtnClick = (event) => {
        event.preventDefault();
        window.location.href = '/markets';
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const marketDetail = {
                country: this.state.country,
                managerOverrideRequired: this.state.managerOverrideRequired
            };

            getHttpCommon(this.state.accessToken).post('api/market', marketDetail)
            .then(res => {
                alert(res.data);
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            <div id="container" className="containerStyle">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1" style={{ marginTop: '20px' }}>
                        <div className="info-form" style={{ marginTop: '20px', width: '50%', margin: 'auto' }}>
                            <h4>Add New Market</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Country"
                                        onChange={this.handleCountryChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Country
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ marginTop: '10px' }}>
                                    <Form.Check type="checkbox" label="Manager Override Required"
                                        onChange={this.handleManagerOverrideRequiredChange} />
                                </Form.Group>
                                <div className='form-group' style={{ marginTop: '20px',marginBottom: '30px' }}>
                                <button className="btnNormal" type="submit" style={{ marginTop: '10px' }}>Add Market</button>
                                <span className="btnNormal3" type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}