import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import httpCommon,{getHttpCommon} from '../../httpCommon'
import {isWritePermission, validateRole} from '../../Constants/Utilities'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";

export default class ShowCountryManagers extends Component {

    static contextType = UserContext;
    constructor(props,context) {
        super(props,context)

        this.state = {
            countryManagers: []
        }


        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
        this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.loginUserPermission = context.userPermission
    }

    componentDidMount() {
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
          console.log(response.accessToken);
            //callMsGraph(response.accessToken).then(response => setGraphData(response));
            this.setState({ accessToken: response.accessToken })
            this.loadCountryList(response.accessToken)
        });
    }

    loadCountryList(accessToken){
        getHttpCommon(accessToken).get('api/countrymanager')
        .then(res => {
            const countryManagers = res.data;
            this.setState({ countryManagers });
        }).catch(function (error) {
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
        });
    }

    deleteRow(id, e) {
        var proceed = window.confirm("Are you sure you want to proceed to delete Country Manager");
        if (proceed) {
            getHttpCommon(this.state.accessToken).delete('api/countrymanager/' + id)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/countrymanagers';
                }).catch(function (error) {
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        } else {
            // alert("Don't Delete "+ id);
        }

    }

    render() {
        return (
            <div className="containerStyle">
                <div className="subHeaderContainer">
                <div className="subTitle">Country Managers ({this.state.countryManagers.length})</div>
                    <div className="subButtonSec">
                    <span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])}  variant="secondary" 
                    onClick={() => this.props.history.push('/createcountrymanager')}>Add Country Manager</span>
                    </div>
                </div>
        
                <Table striped bordered hover>
                    <thead>
                        <tr className="tableHeader">
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Country</th>
                            <th>Brand</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.countryManagers.map((item) => (
                            <tr key={item.id} className="tableRow">
                                 <td>{item.firstName}</td>
                                 <td>{item.lastName}</td>
                                 <td>{item.email}</td>
                                <td>{item.country}</td>
                                <td>{item.brandId}</td>
                                <td>
                                <span className="btnNormal"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])}  onClick={() => this.props.history.push('/updatecountrymanager/' + item.id)}>Update</span>
                                <span className="btnNegative"  disabled={!validateRole(this.loginUserPermission, ["HM_SADMIN","HM_PADMIN"])}  variant="danger" onClick={(e) => this.deleteRow(item.id, e)} style={{ marginLeft: '10px' }}>Delete</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}
